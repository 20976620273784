import { Injectable } from '@angular/core';
import { createStore, setProps, withProps } from '@ngneat/elf';
import { filter } from 'rxjs/operators';
import { Observable } from 'rxjs';

interface VisuallyImpairedProps {
  font_size: number;
  grayscale: boolean;
  high_contrast: boolean;
  negative_contrast: boolean;
  light_background: boolean;
  links_underline: boolean;
  readable_font: boolean;
}


const name = 'yeda_visually_impaired';
const store = createStore(
  { name },
  withProps<VisuallyImpairedProps>({
    grayscale: false,
    high_contrast: false,
    negative_contrast: false,
    light_background: false,
    font_size: 22,
    readable_font: false,
    links_underline: false
  })
);

@Injectable({ providedIn: 'root' })
export class VisuallyImpairedState {
  readonly state$ = store.pipe(filter((state) => !!state)) as Observable<VisuallyImpairedProps>;

  constructor() {

  }

  get snapshot(): VisuallyImpairedProps {
    return store.getValue();
  }

  reset(): void {
    store.reset();
  }

  patch(value: Partial<VisuallyImpairedProps>): void {
    store.update(setProps(value));
  }

  plusFontSize(): void {
    const fontSize = this.snapshot.font_size;

    if (fontSize && fontSize > 0 && fontSize < 9) {
      this.patch({ font_size: fontSize + 1 });
    }
    else if (fontSize === 9) {
      this.patch({ font_size: 9 });
    }
    else {
      this.patch({ font_size: 1 });
    }
  }

  minusFontSize(): void {
    const fontSize = this.snapshot.font_size;

    if (fontSize && fontSize > 0) {
      this.patch({ font_size: fontSize - 1 });
    }
  }

  grayscale(): void {
    this.patch({
      grayscale: true,
      high_contrast: false,
      negative_contrast: false,
      light_background: false
    });
  }

  highContrast(): void {
    this.patch({
      grayscale: false,
      high_contrast: true,
      negative_contrast: false,
      light_background: false
    });
  }

  negativeContrast(): void {
    this.patch({
      grayscale: false,
      high_contrast: false,
      negative_contrast: true,
      light_background: false
    });
  }

  lightBackground(): void {
    this.patch({
      grayscale: false,
      high_contrast: false,
      negative_contrast: false,
      light_background: true
    });
  }

  linksUnderline(): void {
    const links_underline = !this.snapshot.links_underline;
    this.patch({ links_underline });
  }

  readableFont(): void {
    const readable_font = !this.snapshot.readable_font;
    this.patch({ readable_font });
  }

}
