import { inject, Inject, Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { ThemeColorsService } from '@airy-ui/cdk';
import { CollegeSettings, ColorsSettings, ColorsSettingsToken } from 'libraries/college-settings';
import { PreloadAction } from '@shared/modules/air/air-modules/actions/interfaces/preload.action';
import { DOCUMENT } from '@angular/common';

@Injectable()
export class ThemeColorsAction implements PreloadAction {
  private readonly document = inject(DOCUMENT);

  constructor(
    private themeColorsService: ThemeColorsService,
    @Inject(ColorsSettingsToken) readonly colorsSettings: CollegeSettings<ColorsSettings>,
  ) {}

  async preload(): Promise<void> {
    await firstValueFrom(this.colorsSettings.fetch());
    const settings = this.colorsSettings.snapshot;
    this.themeColorsService.set('primary', settings.colors_primary);
    this.themeColorsService.set('accent', settings.colors_accent);
    if (settings.is_dark_theme) {
      this.setDarkTheme(settings);
    }
  }

  private setDarkTheme(settings: ColorsSettings) {
    this.document.body.classList.add('dark-theme');
    if (settings.override_editor_colors) {
      this.document.body.classList.add('dark-theme--override-colors');
    }
  }
}
