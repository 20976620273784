import { CanActivateFn, Router } from '@angular/router';
import { CollegeEnvironmentRepository } from 'libraries/env';
import { inject } from '@angular/core';
import { pages } from 'libraries/college-pages';

export const checkCollegeGuard: CanActivateFn = (route, state) => {
  const collegeEnvironmentState: CollegeEnvironmentRepository = inject(CollegeEnvironmentRepository);
  const router = inject(Router);

  if (collegeEnvironmentState.snapshot.collegeEnvironment.disconnect_college) {
    router.navigate([pages.disconnectedCollege]).then();
    return false;
  }

  return true;
};
