import { environment } from 'libraries/env';
import { CollegeData } from './app/shared/interfaces/college-data.interface';

type HasCollegeId = { college_id: number };

export async function getCollege(): Promise<HasCollegeId & CollegeData> {
  return (await (
    await fetch(environment.api_url + '/colleges/getCollegeIdByDomain?domain=' + window.location.hostname)
  ).json()) as HasCollegeId & CollegeData;
}
