import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from '@shared/shared.module';
import { FixedCallFormComponent } from './fixed-call-form.component';
import { AirLibraryModule } from '@shared/modules/air/air-library/air-library.module';

@NgModule({
  declarations: [FixedCallFormComponent],
  exports: [FixedCallFormComponent],
  imports: [AirLibraryModule, CommonModule, ReactiveFormsModule, SharedModule, TranslateModule],
})
export class FixedCallFormModule {}
