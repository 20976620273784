import { Inject, Injectable } from '@angular/core';
import { WINDOW } from '@ng-web-apis/common';

@Injectable({ providedIn: 'root' })
export class BeforeUnloadService {
  private stack: { [key: string]: string } = {};

  constructor(@Inject(WINDOW) readonly window: Window) {
    this.window.onbeforeunload = (e) => {
      if (Object.keys(this.stack).length) {
        e.returnValue = 'If you leave the page now, the data may not be saved';
      }

      return e;
    };
  }

  add(key: string): void {
    this.stack[key] = key;
  }

  remove(key: string): void {
    delete this.stack[key];
  }
}
