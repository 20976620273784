import { ErrorHandler, Inject, Injector, NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { OverlayModule } from '@angular/cdk/overlay';
import { Meta } from '@angular/platform-browser';
import { MissingTranslationHandler, TranslateModule } from '@ngx-translate/core';
import { ErrorTailorModule } from '@ngneat/error-tailor';
import { AirIconModule } from '@airy-ui/icon';
import { DRAFTS_FILES_API_URL } from '../shared/modules/air/air-modules/ngxs-data-draft/air-drafts-tokens';
import { BackendInterceptor } from 'libraries/interceptors';
import { SharedModule } from '../shared/shared.module';
import { InjectorFacade } from '../shared/facades/injector.facade';
import { COURSES_PROVIDERS } from './providers/courses.providers';
import { COLLEGE_PROVIDERS } from './providers/college.providers';
import { GlobalErrorHandlerService } from './services/error-handler/global-error-handler.service';
import { SeoAction } from './actions/seo.action';
import { PreloadTranslationsAction } from './actions/preload-translations.action';
import { PreloadCollegeAction } from './actions/preload-college.action';
import { MainLoaderAction } from './actions/main-loader.action';
import { ThemeNameAction } from './actions/theme-name.action';
import { ThemeColorsAction } from './actions/theme-colors.action';
import { SetViewportHeightAction } from './actions/set-viewport-height.action';
import { RoutesHistoryAction } from './actions/routes-history.action';
import { ScriptAction } from './actions/script.action';
import { AutoLoginAction } from './actions/auto-login.action';
import { FACEBOOK_PIXEL_PROVIDER } from './providers/facebook-pixel.providers';
import { ShowAdditionalVerificationAction } from './actions/show-additional-verification-action.service';
import { PresetCollegeAction } from './actions/preset-college.action';
import { PreloadSeoSettingsAction } from './actions/preload-seo-settings.action';
import { CollegeEnvironmentRepository } from './state/college-environment.state';
import { LoadersModule } from '../shared-modules/loaders/loaders.module';
import { AirLibraryModule } from '../shared/modules/air/air-library/air-library.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexModule } from '@angular/flex-layout';
import { MomentModule } from 'ngx-moment';
import { AirExpansionModule } from '../shared/modules/air/air-modules/expansion/air-expansion.module';
import { airActions } from '../shared/modules/air/air-modules/actions/helpers/air-actions-providers-factory';
import Hotjar from '@hotjar/browser';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { CollegeSettings, SeoSettings, SeoSettingsToken } from 'libraries/college-settings';
import { provideThemeSettings } from 'libraries/theme';
import { MaterialCssVarsModule } from 'angular-material-css-vars';
import { provideCdnEditorLibraryLoader, provideCkEditorImageUploader } from 'libraries/text-editor';
import { DefaultMissingTranslationHandler } from '@core/services/default-missing-translation-handler';
import { provideFirebaseAuth } from 'libraries/auth';
import { MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';
import { college, isProdEnv } from 'libraries/env';
import { filter, take } from 'rxjs/operators';
import { ShowTermsAction } from '@core/actions/show-terms';
import { provideCustomizerStateListener } from 'libraries/college-customizer';
import { provideNavigationHistory } from 'libraries/navigation-history';
import { Directionality } from '@angular/cdk/bidi';

@NgModule({
  imports: [
    RouterModule,
    CommonModule,
    SharedModule,
    OverlayModule,
    LoadersModule,
    TranslateModule.forRoot({
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useClass: DefaultMissingTranslationHandler,
      },
    }),
    AirLibraryModule,
    AirIconModule.forRoot({
      pathToSprites: '/assets/sprites/',
      defaultSprite: 'sprite',
      pathToStaticIcons: 'assets/icons',
      defaultFormat: 'svg',
    }),
    AirExpansionModule.forRoot({
      defaultIconName: 'arrow-down',
      defaultIconSize: 's',
      defaultIconColorMode: 'stroke',
    }),
    ErrorTailorModule.forRoot({
      errors: {
        useValue: {
          required: 'validator.required',
          email: 'validator.email',
        },
      },
    }),
    FormsModule,
    ReactiveFormsModule,
    FlexModule,
    MomentModule,
    MaterialCssVarsModule.forRoot(),
  ],
  providers: [
    provideNavigationHistory(),
    provideCustomizerStateListener(),
    { provide: Directionality },
    {
      provide: MAT_DIALOG_DEFAULT_OPTIONS,
      useFactory: () => {
        return {
          direction:
            college().settings.default_language === 'he' || college().settings.default_language === 'he_312'
              ? 'rtl'
              : 'ltr',
        };
      },
    },
    provideFirebaseAuth(),
    airActions([
      PresetCollegeAction,
      ThemeColorsAction,
      PreloadTranslationsAction,
      AutoLoginAction,
      PreloadCollegeAction,
      PreloadSeoSettingsAction,
      SeoAction,
      MainLoaderAction,
      ThemeNameAction,
      RoutesHistoryAction,
      SetViewportHeightAction,
      ScriptAction,
      ShowAdditionalVerificationAction,
      ShowTermsAction,
    ]),
    {
      provide: DRAFTS_FILES_API_URL,
      useValue: '{apiNew}/drafts/files',
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: BackendInterceptor,
      multi: true,
    },
    provideCdnEditorLibraryLoader(),
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandlerService,
    },
    provideCkEditorImageUploader(),
    COURSES_PROVIDERS,
    COLLEGE_PROVIDERS,
    FACEBOOK_PIXEL_PROVIDER,
    { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'outline' } },
    provideThemeSettings(),
  ],
})
export class CoreModule {
  constructor(
    injector: Injector,
    private meta: Meta,
    private collegeEnvironmentState: CollegeEnvironmentRepository,
    @Inject(SeoSettingsToken) readonly seoSettings: CollegeSettings<SeoSettings>,
  ) {
    InjectorFacade.setInjector(injector);

    this.collegeEnvironmentState.state$
      .pipe(
        filter((response) => response.college_id !== -1 && isProdEnv()),
        take(1),
      )
      .subscribe(() => this.initSeo());
  }

  initSeo(): void {
    this.seoSettings.state$.subscribe((settings) => {
      const hotjarId = Number(settings.hotjar_id);

      if (hotjarId) {
        Hotjar.init(hotjarId, 6, { debug: false });
      }

      if (settings.google_site_verification) {
        this.meta.addTag({
          name: 'google-site-verification',
          content: settings.google_site_verification,
        });
      }
    });
  }
}
