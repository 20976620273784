import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ErrorTailorModule } from '@ngneat/error-tailor';
import { authErrorConfig } from '../../pages/auth/shared/auth-error-config';
import { SharedModule } from '../shared.module';
import { PagesModule } from '../directives/pages/pages.module';
import { NgxMaskModule } from 'ngx-mask';
import { ModalAskEmailComponent } from './modal-ask-email/modal-ask-email.component';
import { PostmanComponent } from './modal-ask-email/components/postman/postman.component';
import { YedaFormsModule } from '../modules/forms/yeda-forms-module';
import { ModalAdditionalVerificationComponent } from './modal-additional-verification/modal-additional-verification.component';
import { ModalConfirmComponent } from './modal-confirm/modal-confirm.component';
import { ModalDevicesComponent } from './modal-devices/modal-devices.component';
import { ModalRequiredFieldComponent } from './modal-required-field/modal-required-field.component';
import { WelcomeSvgComponent } from './modal-required-field/shared/svg/welcome-svg/welcome-svg.component';
import { ModalWrongAnswerComponent } from './modal-wrong-answer/modal-wrong-answer.component';
import { PaymentButtonModule } from '../modules/payment-button/payment-button.module';
import { ModalTearmsPaymentComponent } from './modal-tearms-payment/modal-tearms-payment.component';
import { AirLibraryModule } from '../modules/air/air-library/air-library.module';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FocusInvalidInputModule } from '../../pages/auth/shared/focus-invalid-input/focus-invalid-input.module';
import { RouterModule } from '@angular/router';
import { TextEditorContentComponent } from 'libraries/text-editor';
import { PhoneNumberComponent } from '../components/phone-number/phone-number.component';

@NgModule({
  declarations: [
    ModalTearmsPaymentComponent,
    ModalAdditionalVerificationComponent,
    ModalAskEmailComponent,
    PostmanComponent,
    ModalConfirmComponent,
    ModalDevicesComponent,
    ModalRequiredFieldComponent,
    WelcomeSvgComponent,
    ModalWrongAnswerComponent,
  ],
  exports: [ModalAskEmailComponent, ModalDevicesComponent],
  imports: [
    AirLibraryModule,
    CommonModule,
    ErrorTailorModule.forRoot(authErrorConfig),
    SharedModule,
    PagesModule,
    PaymentButtonModule,
    NgxMaskModule.forRoot(),
    YedaFormsModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    FocusInvalidInputModule,
    RouterModule,
    TextEditorContentComponent,
    PhoneNumberComponent
  ]
})
export class ModalModule {}
