import { Injectable } from '@angular/core';
import { NavigationStart } from '@angular/router';
import { ThemeNameService } from '@airy-ui/cdk';
import { OnRouteStartAction } from '@shared/modules/air/air-modules/actions/interfaces/on-route-start.action';

@Injectable()
export class ThemeNameAction implements OnRouteStartAction {
  constructor(private themeNameService: ThemeNameService) {}

  onRouteStart(event: NavigationStart): void {
    if (event.url.startsWith('/admin')) {
      this.themeNameService.set('admin');
    } else {
      this.themeNameService.set('wl');
    }
  }
}
