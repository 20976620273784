<div class="modal-support-content">
  <form [formGroup]="form" class="form-content">
    <div class="support-title">
      {{ 'support_course.title' | translate }}
    </div>

    <div class="support-type">
      <label class="support-type-label">{{ 'support_course.type_label' | translate }}</label>
      <mat-radio-group formControlName="type" class="radio-group-mob">
        <mat-radio-button
          value="professional_support"
        >
          {{ 'support_course.type_professional_support' | translate }}
        </mat-radio-button>

        <mat-radio-button value="service_request">
          {{ 'support_course.type_service_request' | translate }}
        </mat-radio-button>
      </mat-radio-group>
    </div>

    <div class="support-form-field">
      <mat-form-field
        appearance="outline" class="support-form-input"
        [ngClass]="{
            'margin-16': !form.controls.theme.hasError('required'),
            'margin-20': form.controls.theme.hasError('required')
            }"
      >
        <mat-label class="support-form-label">
          {{ 'support_course.form_subject_label' | translate }}
        </mat-label>
        <input
          matInput
          [placeholder]="'support_course.form_subject_placeholder' | translate"
          formControlName="theme"
        >

        @if (form.controls.theme.hasError('required')) {
          <mat-error>
            {{ 'validator.required' | translate }}
          </mat-error>
        }
      </mat-form-field>

      <mat-form-field appearance="outline" class="support-form-textarea">
        <mat-label>
          {{ 'support_course.form_message_label' | translate }}
        </mat-label>
        <textarea
          matInput
          [placeholder]="'support_course.form_message_placeholder' | translate"
          formControlName="message"
        ></textarea>

        @if (form.controls.message.hasError('required')) {
          <mat-error>
            {{ 'validator.required' | translate }}
          </mat-error>
        }
      </mat-form-field>

      <button
        mat-flat-button
        color="primary"
        class="support-form-submit"
        (click)="submit()"
        [disabled]="form.invalid || isLoading()"
      >{{ 'support_course.form_submit' | translate }}
      </button>
    </div>

    @if (isSuccess()) {
      <div class="success-message">
        <div class="success-message-icon">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24C18.6274 24 24 18.6274 24 12C23.9923 5.37578 18.6242 0.00771476 12 0Z"
              fill="#01A22A"
            />
            <path
              d="M18.929 8.2L12.084 17.489C11.9207 17.7056 11.6773 17.8475 11.4084 17.8829C11.1395 17.9182 10.8676 17.844 10.654 17.677L5.76595 13.769C5.33462 13.4238 5.26478 12.7943 5.60995 12.363C5.95513 11.9317 6.58462 11.8618 7.01595 12.207L11.092 15.468L17.319 7.017C17.5231 6.71059 17.8792 6.54118 18.2458 6.57604C18.6123 6.61089 18.9301 6.84437 19.0729 7.18377C19.2156 7.52317 19.1604 7.91358 18.929 8.2Z"
              fill="white"
            />
          </svg>
        </div>
        <div class="success-message-text">
          {{ 'support_course.successfully' | translate }}
        </div>
      </div>
    }
  </form>

  <div class="img-content">
    <div class="img-content-close" mat-dialog-close [mat-dialog-close]="true">
      <div class="img-content-close-text">
        {{'support_course.close' | translate}}
      </div>

      <button mat-icon-button class="img-content-close-button">
        <mat-icon>close</mat-icon>
      </button>

    </div>

    <div class="img-content-image">
      <svg width="400" height="344" viewBox="0 0 400 344" fill="none" xmlns="http://www.w3.org/2000/svg">
        <mask
          id="mask0_136_446"
          style="mask-type:alpha"
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="400"
          height="344"
        >
          <rect width="400" height="344" fill="#D9D9D9" />
        </mask>
        <g mask="url(#mask0_136_446)">
          <path
            d="M11.9998 286.4C17.1198 288.64 35.3331 289.467 42.3998 289.6L39.5997 281.6C34.5331 236.667 28.9598 146.8 21.5998 146.8C14.2398 146.8 2.53307 148.933 0.799698 150C-1.7603 183.6 7.19981 255.067 11.9998 286.4Z"
            fill="white"
          />
          <path
            d="M390.578 106.834L390.578 106.833C382.634 78.636 363.381 54.9384 343.397 37.4132C323.425 19.8977 302.559 8.40457 291.159 4.69871C280.863 1.33914 263.244 0.477816 241.947 2.12142C220.591 3.76961 195.355 7.95227 169.723 14.8105C118.546 28.5041 65.4268 52.9623 38.6717 89.5547C9.50781 129.348 7.6997 181.53 17.5441 226.655C22.4712 249.24 30.3307 270.13 39.2114 286.927C48.0685 303.68 58.0222 316.514 67.2191 322.821C76.8637 329.458 94.8635 334.963 117.391 338.543C139.986 342.135 167.345 343.821 195.872 342.691C252.849 340.434 314.848 326.923 352.788 294.537L352.79 294.536C359.86 288.481 366.965 278.115 373.339 264.992C379.726 251.84 385.431 235.81 389.647 218.321C398.073 183.365 400.592 142.415 390.578 106.834Z"
            fill="#F6F9FC"
            stroke="white"
            stroke-width="2.88108"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M282.072 23.6883C302.132 30.222 356.563 64.5845 370.65 114.797C388.568 178.32 361.747 261.443 337.067 282.617C269.45 340.453 115.397 331.257 81.7016 308.026C49.6964 286.005 4.50594 170.576 56.3453 99.673C104.015 34.4568 246.01 11.9518 282.072 23.6883Z"
            fill="#E8EAF5"
          />
          <mask
            id="mask1_136_446"
            style="mask-type:alpha"
            maskUnits="userSpaceOnUse"
            x="32"
            y="20"
            width="345"
            height="307"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M282.072 23.6883C302.132 30.222 356.563 64.5845 370.65 114.797C388.568 178.32 361.747 261.443 337.067 282.617C269.45 340.453 115.397 331.257 81.7016 308.026C49.6964 286.005 4.50594 170.576 56.3453 99.673C104.015 34.4568 246.01 11.9518 282.072 23.6883Z"
              fill="#E8EAF5"
            />
          </mask>
          <g mask="url(#mask1_136_446)">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M334.659 211.172C336.327 205.133 306.604 152.446 319.58 150.081C332.552 147.716 413.161 145.185 416.796 163.401C417.356 166.209 416.477 183.19 415.034 204.817C414.868 207.328 406.081 207.768 406.081 207.768C406.081 207.768 414.549 208.524 414.35 214.573C413.94 227.046 412.331 240.793 412.129 254.064C412.083 257.204 403.322 256.839 403.322 256.839C403.322 256.839 411.849 258.224 411.513 262.129C410.37 275.404 407.253 312.409 405.81 314.022C402.348 317.875 321.348 315.253 321.348 315.253C321.348 315.253 328.738 232.626 334.659 211.172Z"
              style="fill: var(--color-primary-light)"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M116.031 286.589C116.031 286.589 184.259 290.099 194.58 284.92C196.499 283.958 188.831 157.702 183.478 149.549C180.529 145.058 7.7124 147.103 5.41804 151.702C2.80697 156.944 13.5978 282.621 16.1409 283.474C17.3049 283.863 54.699 287.149 77.2054 287.149"
              style="fill: var(--color-primary-light)"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M87.8682 319.051C82.5581 317.453 75.7772 266.299 78.872 251.548C79.862 246.83 83.6818 233.862 90.5988 234.854C98.1202 235.931 100.934 243.276 103.689 249.359C109.92 263.114 111.841 290.318 120.326 300.532C127.374 309.02 161.717 310.53 157.781 319.6L87.8682 319.051Z"
              style="fill: var(--color-primary)"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M159.309 318.146C161.191 313.032 131.635 307.775 125.844 303.77C117.916 298.286 115.895 290.241 114.896 286.757C111.274 274.118 107.228 254.015 105.716 250.48C103.513 245.331 100.075 239.943 96.3333 237.095C95.4356 236.412 95.233 247.535 97.6726 261.85C101.091 281.908 104.526 302.216 113.464 312.886C118.323 318.688 130.91 318.685 141.625 318.472C151.229 318.281 159.309 318.146 159.309 318.146Z"
              style="fill: var(--color-primary-dark)"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M223.775 201.139C222.765 191.021 225.137 165.487 223.206 160.734C220.963 155.212 218.943 170.383 218.943 170.383C218.943 170.383 220.329 163.774 219.768 158.177C219.385 154.343 218.583 148.931 215.91 148.421C214.462 148.145 211.635 158.903 211.066 167.596C210.667 173.719 210.017 179.482 210.913 189.367C211.397 194.726 212.413 198.107 212.423 203.606"
              fill="#FFE4C2"
            />
            <mask
              id="mask2_136_446"
              style="mask-type:luminance"
              maskUnits="userSpaceOnUse"
              x="210"
              y="148"
              width="14"
              height="56"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M223.775 201.139C222.765 191.021 225.137 165.487 223.206 160.734C220.963 155.212 218.943 170.383 218.943 170.383C218.943 170.383 220.329 163.774 219.768 158.177C219.385 154.342 218.583 148.93 215.91 148.421C214.462 148.145 211.635 158.903 211.066 167.596C210.667 173.719 210.017 179.482 210.913 189.367C211.397 194.726 212.413 198.107 212.423 203.606"
                fill="white"
              />
            </mask>
            <g mask="url(#mask2_136_446)">
              <path
                d="M212.709 201.307C208.898 178.959 210.805 166.586 212.119 158.382C212.715 154.652 215.058 148.202 215.991 148.519C218.188 149.26 220.23 158.064 219.62 167.975C219.558 168.992 219.977 162.679 221.221 160.531C222.223 158.794 224.238 161.395 224.787 170.054"
                stroke="black"
                stroke-width="2.24154"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </g>
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M227.303 194.585L227.023 213.644L218.198 218.961L207.97 218.49L208.447 200.931C208.447 200.931 209.292 201.898 210.843 202.315C212.067 202.644 214.354 202.203 215.759 201.895C218.402 201.314 221.112 199.743 223.716 197.95C225.448 196.757 227.163 195.763 227.303 194.585Z"
              fill="#404B88"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M289.863 177.432C289.863 177.432 276.208 179.953 274.131 180.963C270.807 182.58 268.892 185.922 265.795 190.762C261.584 197.341 248.407 261.77 242.116 272.881C239.485 277.528 237.655 277.872 237.384 277.875C233.115 277.943 230.041 255.635 228.53 239.113C226.827 220.487 227.307 208.766 227.307 208.766C227.307 208.766 223.742 212.572 217.848 213.769C211.872 214.98 208.228 214.05 208.228 214.05C208.228 214.05 199.116 295.423 231.721 315.367C242.818 322.159 265.191 282.789 265.191 282.789L265.297 316.637L296.881 317.559C296.881 317.559 357.89 322.304 364.137 309.057C393.573 246.622 383.039 186.091 378.319 181.778C373.363 177.254 329.084 169.968 329.084 169.968L338.673 197.231C338.673 197.231 296.383 235.063 289.326 226.257C282.272 217.451 289.102 179.227 289.863 177.432Z"
              style="fill: var(--color-primary)"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M296.371 147.127C297.002 149.748 298.277 164.169 296.363 169.398C295.117 172.8 295.599 182.259 301.596 182.846C307.627 183.437 321.482 168.905 319.697 163.44C314.55 147.685 315.864 139.324 318.541 136.099C319.675 134.733 320.391 126.413 319.924 116.866C318.321 84.0501 275.729 83.3495 274.966 103.651C274.966 103.651 275.656 109.209 272.319 115.898C268.981 122.586 266.076 130.067 265.857 132.279C265.654 134.34 273.38 135.837 273.38 135.837C273.38 135.837 273.105 152.447 279.36 153.776C284.714 154.914 291.934 151.299 296.371 147.127Z"
              fill="#FFE4C2"
            />
            <path
              d="M309.113 119.346C310.999 116.961 313.732 120.411 309.008 123.439"
              stroke="black"
              stroke-width="2.24154"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M285.017 136.752C285.017 136.752 287.65 137.621 287.154 138.555C285.125 142.384 281.012 144.042 277.794 144.435"
              stroke="black"
              stroke-width="2.24154"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M280.297 119.676C280.297 122.549 283.024 121.157 282.216 119.532C281.944 118.985 278.821 116.138 278.821 117.827"
              stroke="black"
              stroke-width="2.24154"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M278.922 111.679C278.316 109.339 287.317 112.92 288.259 115.288"
              stroke="black"
              stroke-width="2.24154"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M267.242 80.91C267.242 80.91 265.21 107.345 281.627 105.616C298.043 103.888 298.041 97.9991 304.879 105.774C305.957 106.996 307.335 112.749 307.335 112.749C307.335 112.749 317.1 110.329 316.433 117.988C315.751 125.798 309.775 127.893 309.775 127.893C309.775 127.893 314.94 133.937 315.392 141.778C315.664 146.519 345.164 126.809 335.244 93.7249C332.35 84.0698 319.487 78.7999 309.605 78.7999C291.382 78.7948 266.655 90.761 267.242 80.91Z"
              fill="black"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M292.239 256.779C292.239 256.779 284.266 230.386 284.572 224.732C285.338 210.635 288.005 190.231 291.499 178.406C293.129 172.9 297.578 165.548 297.469 165.802C296.279 168.581 298.739 173.617 300.18 174.873C301.085 175.66 304.883 175.687 306.58 175.933C308.775 176.25 315.829 163.285 317.933 159.419C318.511 158.358 323.417 166.964 329.351 170.115C331.087 171.037 292.239 256.779 292.239 256.779Z"
              fill="white"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M294.454 175.466C294.454 175.466 292.117 186.93 291.012 192.381C286.142 216.365 289.365 235.919 292.332 252.714C293.894 261.56 293.841 268.02 293.841 268.02C293.841 268.02 280.178 237.042 276.303 224.408C271.739 209.526 270.124 193.642 270.124 193.642L278.743 187.081L275.307 180.579L294.454 175.466Z"
              style="fill: var(--color-primary-light)"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M292.218 186.532C291.778 186.547 285.366 215.642 290.533 246.258C291.467 251.794 297.149 274.995 288.839 300.668C288.703 301.091 290.082 300.672 291.21 296.134C297.513 270.749 293.027 251.459 292.104 245.99C286.989 215.68 291.763 193.706 292.467 189.606C292.831 187.481 292.511 186.521 292.218 186.532Z"
              fill="#404B88"
            />
            <path
              d="M235.868 277.875C236 276.772 236.922 288.447 236.43 291.727C236.251 292.919 236.095 294.084 235.725 295.226C235.586 295.653 235.489 296.553 235.489 296.553C235.489 296.553 235.556 296.23 235.607 296.072C235.919 295.107 235.911 294.146 236.195 293.177C236.758 291.246 237.096 289.224 237.606 287.258C238.399 284.205 239.565 275.511 239.605 275.676C239.81 276.518 240.015 277.36 240.193 278.21C240.761 280.929 241.043 283.644 241.251 286.416C241.321 287.347 241.369 288.251 241.369 289.193C241.369 289.758 241.251 290.881 241.251 290.881C241.251 290.881 241.525 289.277 241.604 288.466C241.735 287.124 242.038 285.828 242.192 284.486C242.679 280.237 243.654 274.307 243.654 270.011"
              stroke="black"
              stroke-width="2.24154"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M307.941 176.259C308.205 176.92 315.012 195.964 315.372 195.964"
              stroke="black"
              stroke-width="2.24154"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M299.717 175.536C297.763 177.292 294.21 182.247 294.21 182.247"
              stroke="black"
              stroke-width="2.24154"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M298.45 182.64C298.431 182.631 300.637 188.877 302.94 190.034C304.713 190.925 308.011 186.615 309.437 185.183"
              stroke="black"
              stroke-width="2.24154"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M299.942 187.782C300.266 185.871 292.839 216.597 290.927 227.99"
              stroke="black"
              stroke-width="2.24154"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M306.026 189.52C306.254 189.429 308.238 206.612 308.238 212.366"
              stroke="black"
              stroke-width="2.24154"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M288.341 152.42C288.341 152.42 291.347 150.402 292.517 149.508C297.152 145.965 298.798 143.088 300.548 139.947C303.643 134.395 298.613 144.798 296.649 146.804C294.614 148.883 290.88 151.723 288.341 152.42Z"
              fill="black"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M223.479 197.703C223.471 197.656 223.593 194.954 223.593 194.954C223.593 194.954 225.684 194.053 226.208 194.047C226.673 194.041 226.947 194.233 227.111 194.443C227.285 194.666 227.087 195.415 226.566 196.008C225.08 197.698 224.177 199.143 217.482 201.655C213.558 203.128 209.028 203.052 208.492 201.417C208.041 200.039 212.301 199.245 212.301 199.245C212.301 199.245 212.461 201.397 212.433 201.924C212.407 202.411 212.878 202.347 213.588 202.282C216.244 202.04 222.727 198.873 223.479 197.703Z"
              fill="#404B88"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M292.349 247.437L293.616 256.709L347.218 196.159L338.753 186.629L344.908 172.849L328.868 169.501L307.973 215.417L292.349 247.437Z"
              style="fill: var(--color-primary-dark)"
            />
            <path
              d="M343.04 283.77C343.04 283.77 338.981 289.108 338.433 288.747C336.261 287.315 347.291 301.065 347.291 301.065C347.291 301.065 343.982 297.853 342.431 296.136C341.453 295.053 335.828 289.988 335.073 290.257C334.767 290.366 339.01 301.47 339.01 301.47C339.01 301.47 331.361 291.93 330.939 292.615C330.284 293.674 331.015 295.708 329.365 296.137"
              stroke="black"
              stroke-width="2.24154"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M427.604 314.577C403.134 316.089 379.797 315.716 357.303 316.004C336.277 316.272 316.042 317.25 294.797 317.25C214.471 317.25 5.67911 316.223 -82.0122 316.21C-83.9745 316.21 -110.972 325.262 -89.0871 325.066C-0.565964 324.274 338.461 329.535 416.161 320.95C434.477 318.928 427.604 314.577 427.604 314.577Z"
              style="fill: var(--color-primary-light)"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M357.056 227.372C357.056 227.372 356.874 238.436 351.452 254.443C347.514 266.067 341.565 275.296 336.739 282.477C333.296 287.593 329.635 291.948 329.635 291.948C329.635 291.948 336.671 297.758 336.482 297.211C334.864 292.481 335.395 290.145 339.186 292.105C340.374 292.716 338.854 290.024 339.31 289.703C343.983 286.438 354.573 271.468 357.619 249.17C358.855 240.127 357.166 227.532 357.056 227.372Z"
              fill="black"
            />
            <path
              d="M328.897 296.435C317.621 301.813 293.747 303.926 280.944 304.273"
              stroke="black"
              stroke-width="2.24154"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M281.674 317.001C280.31 317.236 283.311 303.469 281.996 303.479C255.395 303.698 244.753 309.512 244.109 315.918C243.875 318.26 272.903 317.001 281.674 317.001Z"
              fill="#FFE4C2"
            />
            <path
              d="M245.694 313.841C245.307 313.9 246.397 313.435 246.436 313.425C247.097 313.236 247.66 312.895 248.317 312.699C250.524 312.032 252.782 311.099 255.112 311.099"
              stroke="black"
              stroke-width="2.24154"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M244.487 315.942C244.64 315.536 247.013 315.549 247.449 315.464C250.29 314.892 253.502 314.299 256.411 314.299"
              stroke="black"
              stroke-width="2.24154"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M282.506 303.867C282.717 304.079 281.048 313.314 281.048 316.533"
              stroke="black"
              stroke-width="2.24154"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </g>
          <mask
            id="mask3_136_446"
            style="mask-type:alpha"
            maskUnits="userSpaceOnUse"
            x="0"
            y="136"
            width="80"
            height="157"
          >
            <path
              d="M75.3089 140.131L0 136.4L10.5161 289.053L79.0405 292.445L62.7575 244.614L75.3089 140.131Z"
              fill="#D9D9D9"
            />
          </mask>
          <g mask="url(#mask3_136_446)">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M116.031 286.589C116.031 286.589 184.259 290.099 194.58 284.92C196.499 283.958 188.831 157.703 183.478 149.549C180.529 145.058 7.7124 147.103 5.41804 151.702C2.80697 156.945 13.5978 282.621 16.1409 283.474C17.3049 283.863 54.699 287.15 77.2054 287.15"
              style="fill: var(--color-primary-light)"
            />
          </g>
        </g>
      </svg>
    </div>
  </div>
</div>