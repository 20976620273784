export const backend_environment_default = {
  lang: 'he_IL',
  cookie_prefix: 'prod-',
  hide_homework_tab: true,
  show_teacher_send_message_button: true,
  deny_login_and_registration: false,
  enable_split_payments: false,
  interactive_lesson: false,
  ministry_of_education: false,
  course_duration: false,
  disconnect_college: false,
};
