import {MissingTranslationHandler, MissingTranslationHandlerParams} from '@ngx-translate/core';

export class DefaultMissingTranslationHandler implements MissingTranslationHandler {
  handle(params: MissingTranslationHandlerParams) {
    if (params.interpolateParams) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      return params.interpolateParams["default"] || params.key;
    }
    return params.key;
  }
}
