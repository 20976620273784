<div
  class="whats-app-options"
  [ngClass]="{
    'whats-app-ltr': direction === 'ltr',
    'whats-app-rtl': direction === 'rtl'
  }">
  <a href="https://wa.me/972533382585">
    <svg width="29" height="30" viewBox="0 0 29 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0.11742 14.9699C0.116573 17.4327 0.760083 19.8371 1.98331 21.9562L0 29.1972L7.41063 27.2542C9.45249 28.3676 11.7512 28.9544 14.0907 28.9554H14.0967C21.8007 28.9554 28.0726 22.6855 28.0758 14.9807C28.0771 11.2464 26.6241 7.73533 23.9851 5.094C21.3456 2.45267 17.8361 0.997217 14.0967 0.995605C6.3911 0.995605 0.120408 7.26428 0.117184 14.9695M4.53028 21.5911L4.25365 21.1518C3.09042 19.3024 2.47649 17.165 2.47733 14.9705C2.47987 8.56645 7.69189 3.35592 14.1008 3.35592C17.2044 3.35713 20.1213 4.56706 22.3153 6.76229C24.5091 8.95767 25.7163 11.8761 25.7154 14.9796C25.7126 21.3841 20.5005 26.595 14.0964 26.595H14.0919C12.0066 26.5938 9.96163 26.0341 8.17818 24.9758L7.75371 24.7242L3.35593 25.8772L4.53028 21.5911ZM14.0967 28.9554C14.0965 28.9554 14.0964 28.9554 14.0967 28.9554Z"
        fill="url(#paint0_linear_1016_111)" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.603 9.1273C10.3413 8.54561 10.0659 8.53397 9.81703 8.52373C9.61333 8.51507 9.38036 8.51563 9.14766 8.51563C8.91467 8.51563 8.53626 8.60305 8.21628 8.95259C7.89601 9.30214 6.99365 10.1471 6.99365 11.8655C6.99365 13.5842 8.24545 15.2448 8.41985 15.478C8.59455 15.7109 10.8363 19.3502 14.3863 20.7502C17.3369 21.9137 17.9374 21.6823 18.5778 21.624C19.2182 21.5659 20.6444 20.7793 20.9354 19.9637C21.2265 19.1482 21.2265 18.4491 21.1392 18.3031C21.0519 18.1575 20.8189 18.0701 20.4697 17.8956C20.1203 17.7209 18.4031 16.8758 18.083 16.7592C17.7627 16.6428 17.5298 16.5846 17.2968 16.9343C17.064 17.2836 16.395 18.0701 16.1912 18.3031C15.9875 18.5365 15.7836 18.5657 15.4344 18.391C15.085 18.2157 13.96 17.8472 12.6255 16.6574C11.5871 15.7316 10.8861 14.5883 10.6822 14.2386C10.4785 13.8893 10.6605 13.7001 10.8356 13.5259C10.9926 13.3694 11.185 13.1181 11.3597 12.9143C11.534 12.7103 11.5922 12.5647 11.7086 12.3317C11.8251 12.0986 11.7669 11.8946 11.6796 11.7199C11.5922 11.5452 10.9136 9.818 10.603 9.1273Z"
        fill="white" />
      <defs>
        <linearGradient
          id="paint0_linear_1016_111"
          x1="14.0381"
          y1="29.198"
          x2="14.0381"
          y2="0.996453"
          gradientUnits="userSpaceOnUse">
          <stop stop-color="#F9F9F9" />
          <stop offset="1" stop-color="white" />
        </linearGradient>
      </defs>
    </svg>
  </a>
</div>
