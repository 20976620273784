import { ChangeDetectionStrategy, Component, effect, inject, signal } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { MatButton, MatFabButton } from '@angular/material/button';
import { MatMenu, MatMenuItem, MatMenuTrigger } from '@angular/material/menu';
import { MatIcon } from '@angular/material/icon';
import { AuthAccountsRepository, AuthRepository, AuthState } from 'libraries/auth';
import { TranslateModule } from '@ngx-translate/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { MatChip } from '@angular/material/chips';
import { Router, RouterLink } from '@angular/router';
import { MatDialogModule } from '@angular/material/dialog';
import { useDynamicPages } from '../functions';
import { MatDivider } from '@angular/material/divider';
import { UserAvatarComponent } from 'libraries/user-avatar';
import { CollegeEnvironmentRepository, environment } from 'libraries/env';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AuthSettingsRepository } from 'libraries/college-settings';
import { BreakpointsService } from 'libraries/breakpoints';

@Component({
  selector: 'yeda-wl-admin-menu',
  standalone: true,
  imports: [
    CommonModule,
    MatFabButton,
    MatMenu,
    MatMenuItem,
    MatIcon,
    MatMenuTrigger,
    MatButton,
    TranslateModule,
    MatChip,
    NgOptimizedImage,
    RouterLink,
    MatDialogModule,
    MatDivider,
    UserAvatarComponent,
  ],
  templateUrl: './wl-admin-menu.component.html',
  styleUrl: './wl-admin-menu.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
@UntilDestroy()
export class WlAdminMenuComponent {
  readonly bp = inject(BreakpointsService);
  readonly authRepository = inject(AuthRepository);
  readonly authSettingsRepository = inject(AuthSettingsRepository);
  readonly #router = inject(Router);
  readonly authAccountsRepository = inject(AuthAccountsRepository);
  readonly accounts = toSignal(this.authAccountsRepository.accounts$, { initialValue: [] });
  readonly containsTeacherOrHigher = toSignal(this.authAccountsRepository.containsTeacherOrHigher$, {
    initialValue: false,
  });
  readonly dynamicPages = toSignal(useDynamicPages(), { initialValue: [] });
  readonly usersPath = signal<string>('');
  readonly collegeEnv = inject(CollegeEnvironmentRepository);

  constructor() {
    this.authSettingsRepository.fetch().subscribe();
    effect(
      () => {
        this.usersAdminPanelUrl('users');
      },
      { allowSignalWrites: true },
    );
  }

  async switchTo(account: AuthState): Promise<void> {
    await this.authRepository.logout({ deleteState: false });
    this.authRepository.auth(account);
  }

  async addAccount() {
    await this.authRepository.logout({ deleteState: false });
    await this.#router.navigate(['/auth/login']);
  }

  usersAdminPanelUrl(url: string): void {
    this.authRepository.state$.pipe(untilDestroyed(this)).subscribe((response) => {
      this.usersPath.set(
        `${environment.url}/collegeadmin/${url}?college_id=${this.collegeEnv?.collegeId}&access_token=${response.auth.access_token}`,
      );
    });
  }
}
