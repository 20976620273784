import { ActivatedRoute, NavigationEnd } from '@angular/router';
import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { OnRouteEndAction } from '@shared/modules/air/air-modules/actions';
import { PagesService } from '@shared/services/pages/pages.service';
import { AuthState } from '@state/auth/auth.state';
import { CollegeSettings, CollegeSettingsService, SeoSettings, SeoSettingsToken } from 'libraries/college-settings';
import { CollegeState } from '@state/college/college.state';
import { CollegeEnvironmentRepository } from '@core/state/college-environment.state';
import { pages } from 'libraries/college-pages';

@Injectable()
export class ScriptAction implements OnRouteEndAction {
  constructor(
    private activatedRoute: ActivatedRoute,
    private pagesService: PagesService,
    private authState: AuthState,
    private settingsService: CollegeSettingsService,
    private collegeEnvironmentState: CollegeEnvironmentRepository,
    private collegeState: CollegeState,
    @Inject(DOCUMENT) private document: Document,
    @Inject(SeoSettingsToken) readonly seoSettings: CollegeSettings<SeoSettings>,
  ) {}

  onRouteEnd(event: NavigationEnd): void {
    this.addBackofficeContactWidget();
  }

  private addBackofficeContactWidget(): void {
    const head: HTMLHeadElement = this.document.getElementsByTagName('head')[0];
    const widgetKey = this.settingsService.defaultSettings.backoffice_contact_widget_key;

    // eslint-disable-next-line max-len
    if (
      widgetKey &&
      !this.pagesService.page('admin') &&
      !this.pagesService.page('auth') &&
      !this.pagesService.paymentPage(pages.payment) &&
      !this.pagesService.paymentPage(pages.thankYou) &&
      !this.authState.loggedIn
    ) {
      if (!this.document.getElementById('backoffice-contact-widget-script')) {
        const script: HTMLScriptElement = this.document.createElement('script');
        script.id = 'backoffice-contact-widget-script';
        script.async = true;
        script.charset = 'utf-8';
        script.src = 'https://backoffice.contact.org.il/widget.js';
        head.appendChild(script);
      }

      if (!this.document.getElementById('backoffice-contact-widget-div')) {
        const div: HTMLDivElement = this.document.createElement('div');
        div.id = 'backoffice-contact-widget-div';
        div.className = 'contact-widget';
        div.setAttribute('data-widget-key', widgetKey);
        const body: HTMLBodyElement = this.document.getElementsByTagName('body')[0];
        body.appendChild(div);
      }
    }

    if (
      !widgetKey ||
      this.pagesService.page('admin') ||
      this.pagesService.page('auth') ||
      this.pagesService.paymentPage(pages.payment) ||
      this.pagesService.paymentPage(pages.thankYou) ||
      this.authState.loggedIn
    ) {
      this.document.getElementById('backoffice-contact-widget-script')?.remove();
      this.document.getElementById('backoffice-contact-widget-div')?.remove();
    }
  }
}
