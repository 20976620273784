import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CollegeEnvironmentRepository, environment } from 'libraries/env';
import { catchError, tap } from 'rxjs/operators';
import { BeforeUnloadHttpRequestsService } from './before-unload/before-unload-http-requests.service';
import { AuthRepository } from 'libraries/auth';

@Injectable()
export class BackendInterceptor implements HttpInterceptor {
  private readonly authRepository = inject(AuthRepository);
  private beforeUnloadHttpRequestsService = inject(BeforeUnloadHttpRequestsService);
  private collegeEnvironmentState = inject(CollegeEnvironmentRepository);
  private urlReplaces = [
    ['{api}', environment.api_url + '/wl'],
    ['{apiNew}', environment.api_url + ''],
    ['{college}', 'colleges/' + this.collegeEnvironmentState.collegeId],
  ];

  private defaultParams = {
    current_college_id: '-1',
  };

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.beforeUnloadHttpRequestsService.isChangeableRequest(req.method)) {
      this.beforeUnloadHttpRequestsService.add(req);
    }

    this.setUrlReplacesAndDefaultParams();
    const headers = this.authRepository.loggedIn()
      ? req.headers
          .set('Authorization', 'Bearer ' + this.authRepository.snapshot.auth.access_token)
          .set('Accept', 'application/json')
          .set(
            'Device',
            this.authRepository.snapshot?.device ? JSON.stringify(this.authRepository.snapshot?.device) : '',
          )
      : req.headers;
    if (this.isApiRequest(req.url)) {
      return next
        .handle(
          req.clone({
            url: this.replaceUrl(req.url),
            headers,
            setParams: this.defaultParams,
          }),
        )
        .pipe(
          tap(() => this.beforeUnloadHttpRequestsService.remove(req)),
          catchError((err) => {
            this.beforeUnloadHttpRequestsService.remove(req);
            throw err;
          }),
        );
    }

    return next.handle(req).pipe(
      tap(() => this.beforeUnloadHttpRequestsService.remove(req)),
      catchError((err) => {
        this.beforeUnloadHttpRequestsService.remove(req);
        throw err;
      }),
    );
  }

  private replaceUrl(url: string): string {
    this.urlReplaces.forEach((urls) => {
      const [search, replace] = urls;
      url = url.replace(search, replace);
    });

    return url;
  }

  private isApiRequest(url: string): boolean {
    return Boolean(url.search('{api}') + 1) || Boolean(url.search('{apiNew}' + 1));
  }

  private setUrlReplacesAndDefaultParams(): void {
    this.urlReplaces = [
      ['{api}', environment.api_url + '/wl'],
      ['{apiNew}', environment.api_url],
      ['{college}', 'colleges/' + this.collegeEnvironmentState.collegeId],
    ];

    this.defaultParams = {
      current_college_id: this.collegeEnvironmentState.collegeId.toString(),
    };
  }
}
