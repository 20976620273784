<div
  class="fixed-call-form"
  *ngIf="!loggedIn && contactsPhone"
  [ngClass]="{
    'opened-shadow': form_opened,
    rtl: direction === DirectionEnum.RTL,
    ltr: direction === DirectionEnum.LTR
  }">
  <div class="fixed-call-form--toggle" (click)="toggleCallForm()" [class.opened]="!form_opened && !sent">
    <div class="fixed-call-form--image" [ngClass]="freeCallIconClass"></div>
    <div airH4 class="fixed-call-form--header desktop-only">{{ 'callform.make_call' | translate }}</div>
  </div>
  <div *ngIf="sent" class="on-success">
    <img src="assets/fixed-call-form/done_icon2.svg" alt="" />
  </div>
  <div [class.opened]="form_opened && !sent" class="form-wrapper" [ngStyle]="{ direction: direction }">
    <button class="close-button" type="button" (click)="closeForm()">
      <i-icon [dynamic]="true" name="close" [width]="12" [height]="12"></i-icon>
    </button>
    <div role="form" lang="he-IL">
      <div class="screen-reader-response"></div>
      <form
        class="back-call-form"
        method="post"
        [formGroup]="callForm"
        (submit)="sendCallRequest()"
        [class.tried-to-send]="tried_to_send">
        <div class="title-block" airText2>
          <span>{{ 'callform.contact_us_title' | translate }}</span>
          <air-dynamic-icon
            size="m"
            [name]="freeCallIconName"
            color="primary"
            airMarginEnd="xxs"
            sprite="fixed-call-form"></air-dynamic-icon>
        </div>
        <p>
          <input
            type="text"
            airText1
            size="40"
            [placeholder]="'*' + ('callform.full_name' | translate)"
            formControlName="name"
            [ngClass]="{ 'focus-input-border': inputFocusName === 'name' }"
            (focus)="inputFocusName = 'name'"
            (blur)="inputFocusName = ''" />
          <input
            type="tel"
            airText1
            size="40"
            [placeholder]="('callform.phone' | translate) + '*'"
            formControlName="phone"
            (paste)="pastePhone($event)"
            [ngClass]="{ 'focus-input-border': inputFocusName === 'phone' }"
            (focus)="inputFocusName = 'phone'"
            (blur)="inputFocusName = ''"
            [ngStyle]="{ 'text-align': direction === DirectionEnum.RTL ? SharedEnum.RIGHT : SharedEnum.LEFT }" />
          <input
            type="email"
            airText1
            size="40"
            [placeholder]="'callform.email' | translate"
            formControlName="email"
            [ngClass]="{ 'focus-input-border': inputFocusName === 'email' }"
            (focus)="inputFocusName = 'email'"
            (blur)="inputFocusName = ''" />
        </p>

        <p id="submit-wrapper">
          <input airText2 class="call-btn" type="submit" [value]="'callform.send' | translate" />
        </p>
        <div class="response-output"></div>
      </form>
    </div>
  </div>
</div>
