import { OnRouteEndAction } from '@shared/modules/air/air-modules/actions';
import { ActivatedRoute, NavigationEnd } from '@angular/router';
import { SeoInterface } from '@app/interfaces/seo.interface';
import { SeoService } from '@shared/services/seo/seo.service';
import { Injectable } from '@angular/core';

@Injectable()
export class SeoAction implements OnRouteEndAction {
  constructor(
    private activatedRoute: ActivatedRoute,
    private seoService: SeoService,
  ) {}

  onRouteEnd(event: NavigationEnd): void {
    const seo = this.getSeoData();

    if (seo) {
      if (seo.title) {
        this.seoService.setTitle(seo.title);
      } else {
        this.seoService.resetTitle();
      }

      if (seo.description) {
        this.seoService.setDescription(seo.description);
      } else {
        this.seoService.resetDescription();
      }
    }
  }

  private getSeoData(): SeoInterface | null {
    let child = this.activatedRoute.firstChild;
    while (child) {
      if (child.firstChild) {
        child = child.firstChild;
      } else {
        return child.snapshot.data.seo as SeoInterface;
      }
    }
    return null;
  }
}
