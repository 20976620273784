import { Router } from '@angular/router';
import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnInit,
  ChangeDetectorRef,
  AfterViewInit,
  signal,
} from '@angular/core';
import { DirectionService } from '@airy-ui/direction';
import { DOCUMENT } from '@angular/common';
import { VisuallyImpairedState } from '../../../../state/visually-impaired/visually-impaired.state';
import { DirectionEnum } from '../../../enums/direction.enum';

@Component({
  selector: 'yeda-visually-impaired',
  templateUrl: './visually-impaired.component.html',
  styleUrls: ['./visually-impaired.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VisuallyImpairedComponent implements OnInit, AfterViewInit {
  show = false;
  isShow = signal<boolean>(false);
  readonly direction = this.directionService.direction;
  readonly DirectionEnum = DirectionEnum;

  constructor(
    private directionService: DirectionService,
    @Inject(DOCUMENT) private document: Document,
    public visuallyImpairedState: VisuallyImpairedState,
    public router: Router,
    public cdr: ChangeDetectorRef
  ) {
  }

  ngOnInit(): void {
    this.addClassA11y();
    this.isShow.set(false);
    this.cdr.markForCheck();
  }

  ngAfterViewInit(): void {
    this.isShow.set(false);
    this.cdr.markForCheck();
  }

  plusFontSize(): void {
    this.removeClassA11y();
    this.visuallyImpairedState.plusFontSize();
    this.addClassA11y();
  }

  minusFontSize(): void {
    this.removeClassA11y();
    this.visuallyImpairedState.minusFontSize();
    this.addClassA11y();
  }

  grayscale(): void {
    this.visuallyImpairedState.grayscale();
    this.removeClassA11y();
    this.addClassA11y();
  }

  highContrast(): void {
    this.visuallyImpairedState.highContrast();
    this.removeClassA11y();
    this.addClassA11y();
  }

  negativeContrast(): void {
    this.visuallyImpairedState.negativeContrast();
    this.removeClassA11y();
    this.addClassA11y();
  }

  lightBackground(): void {
    this.visuallyImpairedState.lightBackground();
    this.removeClassA11y();
    this.addClassA11y();
  }

  linksUnderline(): void {
    this.visuallyImpairedState.linksUnderline();
    this.removeClassA11y();
    this.addClassA11y();
  }

  readableFont(): void {
    this.visuallyImpairedState.readableFont();
    this.removeClassA11y();
    this.addClassA11y();
  }

  reset(): void {
    this.removeClassA11y();
    this.visuallyImpairedState.reset();
  }

  showHelp(): void {
    this.router.navigate(['/accessibility-help']).then(() => {
      this.isShow.set(false);
      this.cdr.markForCheck();
    });
  }

  clickedOutside(): void {
    this.isShow.set(false);
    this.cdr.markForCheck();
  }

  private addClassA11y(): void {
    if (this.visuallyImpairedState.snapshot.font_size > 0) {
      this.document.body.classList.add('a11y-font-size-' + this.visuallyImpairedState.snapshot.font_size);
    }

    if (this.visuallyImpairedState.snapshot.grayscale) {
      this.document.body.classList.add('a11y-grayscale');
    }

    if (this.visuallyImpairedState.snapshot.high_contrast) {
      this.document.body.classList.add('a11y-high-contrast');
    }

    if (this.visuallyImpairedState.snapshot.negative_contrast) {
      this.document.body.classList.add('a11y-negative-contrast');
    }

    if (this.visuallyImpairedState.snapshot.light_background) {
      this.document.body.classList.add('a11y-light-background');
    }

    if (this.visuallyImpairedState.snapshot.links_underline) {
      this.document.body.classList.add('a11y-links-underline');
    }

    if (this.visuallyImpairedState.snapshot.readable_font) {
      this.document.body.classList.add('a11y-readable-font');
    }
  }

  private removeClassA11y(): void {
    this.document.body.classList.remove('a11y-font-size-' + this.visuallyImpairedState.snapshot.font_size);
    this.document.body.classList.remove('a11y-grayscale');
    this.document.body.classList.remove('a11y-high-contrast');
    this.document.body.classList.remove('a11y-negative-contrast');
    this.document.body.classList.remove('a11y-light-background');
    this.document.body.classList.remove('a11y-links-underline');
    this.document.body.classList.remove('a11y-readable-font');
  }

  openOrClose(): void {
    this.isShow.set(!this.isShow());
  }
}
