import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit, signal } from '@angular/core';
import { filter } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { CollegeInterface } from '@app/state/college/interfaces/college.interface';
import { TranslateService } from '@ngx-translate/core';
import { COLLEGE$ } from '@core/providers/college.providers';
import { Observable } from 'rxjs';
import { AuthState } from '@state/auth/auth.state';
import { UserDevicesState } from '@pages/edit-user/state/user-devices.state';
import { CheckDevicesInterface } from '@pages/edit-user/state/interfaces/check-devices.interface';
import { CurrentUserInterface } from '@app/state/auth/interfaces/current-user.interface';
import { ModalAskEmailService } from '@shared/modals/modal-ask-email/modal-ask-email.service';
import { LoginState } from '@pages/auth/shared/state/login-state';
import { ModalRequiredFieldService } from '@shared/modals/modal-required-field/service/modal-required-field.service';
import { CollegeEnvironmentRepository } from '@core/state/college-environment.state';
import {
  BasicSettings,
  BasicSettingsToken,
  CollegeSettings,
  ContactsSettings,
  ContactsSettingsToken,
  FooterSettings,
  FooterSettingsToken,
  HeaderSettings,
  HeaderSettingsToken,
} from 'libraries/college-settings';

@Component({
  selector: 'yeda-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
@UntilDestroy()
export class AppComponent implements OnInit {
  college?: CollegeInterface;
  isLoading = true;
  engineeringWorks = true;

  constructor(
    public router: Router,
    private route: ActivatedRoute,
    private translateService: TranslateService,
    @Inject(COLLEGE$) readonly college$: Observable<CollegeInterface>,
    public authState: AuthState,
    private cdr: ChangeDetectorRef,
    private devicesState: UserDevicesState,
    private askEmailService: ModalAskEmailService,
    private loginState: LoginState,
    private modalRequiredFieldService: ModalRequiredFieldService,
    public readonly collegeEnvironmentState: CollegeEnvironmentRepository,
    @Inject(BasicSettingsToken) readonly basicSettings: CollegeSettings<BasicSettings>,
    @Inject(HeaderSettingsToken) readonly headerSettings: CollegeSettings<HeaderSettings>,
    @Inject(FooterSettingsToken) readonly footerSettings: CollegeSettings<FooterSettings>,
    @Inject(ContactsSettingsToken) readonly contactsSettings: CollegeSettings<ContactsSettings>,
  ) {
    this.basicSettings.fetch().subscribe(() => this.cdr.markForCheck());
    this.headerSettings.fetch().subscribe(() => this.cdr.markForCheck());
    this.contactsSettings.fetch().subscribe(() => this.cdr.markForCheck());
    this.footerSettings.fetch().subscribe(() => this.cdr.markForCheck());
    this.translateService.setDefaultLang(this.collegeEnvironmentState.snapshot.collegeEnvironment.lang);
  }

  ngOnInit(): void {
    if (this.route.snapshot.queryParamMap.get('device')) {
      this.router.navigate([], { queryParams: {} }).then();
    }
    this.authState.calcUUID();

    this.loadCollege();
    this.checkDevices();
  }

  private loadCollege(): void {
    this.college$
      .pipe(
        untilDestroyed(this),
        filter((college) => Boolean(college)),
      )
      .subscribe((college) => {
        this.college = college as CollegeInterface;
      });
  }

  private loadCurrentUser(): void {
    if (this.authState.loggedIn) {
      this.authState
        .fetch()
        .pipe(untilDestroyed(this))
        .subscribe((user: CurrentUserInterface) => {
          if (!user.email_is_validated) {
            this.askEmailService.open();
          }

          this.modalRequiredFieldService.openAfterLogin();
        });
    }
  }

  private checkDevices(): void {
    if (this.authState.loggedIn) {
      this.devicesState
        .checkDevices(this.authState.user.id)
        .pipe(untilDestroyed(this))
        .subscribe({
          next: (check: CheckDevicesInterface) => {
            const limitExceeded =
              this.devicesState.checkDevicesInfo.limitDevicesCount &&
              check.devices &&
              check.devices.length >= this.devicesState.checkDevicesInfo.maxDevicesNumber;

            this.loginState.patch({ isClosedDevice: check.isClosedDevice, limitExceeded });

            if (check.isClosedDevice || limitExceeded) {
              this.router
                .navigate([], {
                  queryParams: { devices: 1 },
                  queryParamsHandling: 'merge',
                })
                .then();
            }

            this.loadCurrentUser();
          },
          error: () => {
            console.error('checkDevices error');
          },
        });
    }
  }
}
