import { OnAppInitAction } from '@shared/modules/air/air-modules/actions';
import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import {
  AdditionalVerificationState
} from '@shared/modals/modal-additional-verification/state/additional-verification.state';
import { OtpRedirectState } from '@pages/auth/shared/state/otp-redirect.state';

@Injectable()
export class MainLoaderAction implements OnAppInitAction {
  constructor(
    @Inject(DOCUMENT) readonly document: Document,
    private additionalVerificationState: AdditionalVerificationState,
    private otpRedirectState: OtpRedirectState,
  ) {
  }

  onInit(): void {
    this.setStatusPassed();

    const loader = this.document.getElementById('before-app-init-loader');
    const app = this.document.getElementById('app');
    if (loader) {
      loader.style.display = 'none';
    }

    if (app) {
      app.classList.remove('blur');
    }
  }

  private setStatusPassed(): void {
    const resStatusPassed = localStorage.getItem('statusPassed');

    if (resStatusPassed === null) {
      this.additionalVerificationState.patch({ passed: false });
    }
    else {
      this.additionalVerificationState.patch({ passed: JSON.parse(resStatusPassed) });
    }
  }
}
