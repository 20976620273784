import { APP_INITIALIZER, Injector, Provider } from '@angular/core';
import { ColorsSettingsToken } from 'libraries/college-settings';
import { firstValueFrom } from 'rxjs';
import { CollegeEnvironmentRepository } from 'libraries/env';
import { argbFromHex, hexFromArgb, themeFromSourceColor, TonalPalette } from '@material/material-color-utilities';

const DEFAULT_COLOR = '#0A59EB';

type colorsFromPaletteConfig = {
  primary: { hex: string; tone: number }[];
  secondary: { hex: string; tone: number }[];
  tertiary: { hex: string; tone: number }[];
  neutral: { hex: string; tone: number }[];
  neutralVariant: { hex: string; tone: number }[];
  error: { hex: string; tone: number }[];
};

export function provideThemeSettings(): Provider {
  return {
    multi: true,
    provide: APP_INITIALIZER,
    deps: [Injector],
    useFactory: (injector: Injector) => {
      const colorsSettings = injector.get(ColorsSettingsToken);
      const envState = injector.get(CollegeEnvironmentRepository);
      return async () => {
        await firstValueFrom(envState.state$);
        await firstValueFrom(colorsSettings.fetch());
        const settings = colorsSettings.snapshot;

        themeFromSelectedColor(settings.colors_primary, settings.is_dark_theme);
      };
    },
  };
}

export function themeFromSelectedColor(color?: string, isDark?: boolean): void {
  const tones = [0, 10, 20, 25, 30, 35, 40, 50, 60, 70, 80, 90, 95, 99, 100];

  const theme = themeFromSourceColor(argbFromHex(color?.trim() ? color : DEFAULT_COLOR));

  const colors = Object.entries(theme.palettes).reduce((acc: any, curr: [string, TonalPalette]) => {
    const hexColors = tones.map((tone) => ({
      tone,
      hex: hexFromArgb(curr[1].tone(tone)),
    }));

    return { ...acc, [curr[0]]: hexColors };
  }, {});

  createCustomProperties(colors, 'p');
}

export function createCustomProperties(colorsFromPaletteConfig: colorsFromPaletteConfig, paletteKey: 'p' | 't') {
  let styleString = ':root,:host{';

  for (const [key, palette] of Object.entries(colorsFromPaletteConfig)) {
    palette.forEach(({ hex, tone }) => {
      if (key === 'primary') {
        styleString += `--${key}-${tone}:${hex};`;
      } else {
        styleString += `--${paletteKey}-${key}-${tone}:${hex};`;
      }
    });
  }
  styleString += '}';

  styleString += '}';

  applyThemeString(styleString);
}

export function applyThemeString(themeString: string) {
  const s = document.createElement('style');
  s.innerHTML = themeString;
  s.id = 'yeda-material-theme';
  document.getElementById('yeda-material-theme')?.remove();
  document.head.appendChild(s);
}
