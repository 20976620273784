import { Routes } from '@angular/router';
import { AdminGuard } from '../guards/admin.guard';
import { checkCollegeGuard } from '@core/guards/check-college.guard';
import { signInEditRoutes, signInRoutes, signInStepsVerificationFinishedGuard } from 'features/sign-in';
import { signUpEditRoutes, signUpRoutes, signUpStepsVerificationFinishedGuard } from 'features/sign-up';
import { provideCustomizerRoutes } from 'libraries/college-customizer';
import { AuthOptionalGuard } from '@core/guards/auth-optional.guard';
import { AuthGuard } from '@core/guards/auth.guard';
import { GuestGuard } from '@core/guards/guest.guard';
import { ShowStudentsGuard } from '@core/guards/show-students.guard';

export const routes: Routes = [
  {
    path: '',
    loadComponent: () =>
      import('../../shared/layout/main-layout/main-layout.component').then((c) => c.MainLayoutComponent),
    children: [
      {
        path: 'סטודנט/:username',
        redirectTo: 'student/:username',
      },
      {
        path: 'student/:username',
        loadChildren: () => import('@app/pages/students/students.module').then((m) => m.StudentsModule),
        canActivate: [
          checkCollegeGuard,
          AuthOptionalGuard,
          signUpStepsVerificationFinishedGuard,
          signInStepsVerificationFinishedGuard,
        ],
      },
      {
        path: 'bundles',
        loadChildren: () => import('@app/pages/bundles/bundles.module').then((m) => m.BundlesModule),
        canActivate: [
          checkCollegeGuard,
          AuthOptionalGuard,
          signUpStepsVerificationFinishedGuard,
          signInStepsVerificationFinishedGuard,
        ],
      },
      {
        path: 'articles',
        loadChildren: () => import('@app/pages/articles/articles.module').then((m) => m.ArticlesModule),
        canActivate: [
          checkCollegeGuard,
          AuthOptionalGuard,
          signUpStepsVerificationFinishedGuard,
          signInStepsVerificationFinishedGuard,
        ],
      },
      {
        path: 'articles/:slug',
        loadChildren: () => import('@app/pages/article/article.module').then((m) => m.ArticleModule),
        canActivate: [
          checkCollegeGuard,
          AuthOptionalGuard,
          signUpStepsVerificationFinishedGuard,
          signInStepsVerificationFinishedGuard,
        ],
      },
      {
        path: 'teachers',
        loadChildren: () => import('@app/pages/teachers/teachers.module').then((m) => m.TeachersModule),
        canActivate: [
          checkCollegeGuard,
          AuthOptionalGuard,
          signUpStepsVerificationFinishedGuard,
          signInStepsVerificationFinishedGuard,
        ],
      },
      {
        path: 'password',
        loadChildren: () =>
          import('@app/pages/auth/password-reset/password-reset.module').then((m) => m.PasswordResetModule),
        canActivate: [checkCollegeGuard, signUpStepsVerificationFinishedGuard, signInStepsVerificationFinishedGuard],
      },
      {
        path: 'contacts',
        loadChildren: () => import('@app/pages/contacts/contacts.module').then((m) => m.ContactsModule),
        canActivate: [
          checkCollegeGuard,
          AuthOptionalGuard,
          signUpStepsVerificationFinishedGuard,
          signInStepsVerificationFinishedGuard,
        ],
      },
      {
        path: 'courses',
        loadChildren: () => import('@app/pages/courses/courses.module').then((m) => m.CoursesModule),
        canActivate: [
          checkCollegeGuard,
          AuthOptionalGuard,
          signUpStepsVerificationFinishedGuard,
          signInStepsVerificationFinishedGuard,
        ],
      },
      {
        path: 'about',
        loadChildren: () => import('@app/pages/about-college/about-college.module').then((m) => m.AboutCollegeModule),
        canActivate: [
          checkCollegeGuard,
          AuthOptionalGuard,
          signUpStepsVerificationFinishedGuard,
          signInStepsVerificationFinishedGuard,
        ],
      },
      {
        path: 'my-courses/all',
        redirectTo: 'my-studies',
      },
      {
        path: 'my-studies',
        loadChildren: () => import('@app/pages/my-studies/my-studies.module').then((m) => m.MyStudiesModule),
        canActivate: [
          checkCollegeGuard,
          AuthGuard,
          signUpStepsVerificationFinishedGuard,
          signInStepsVerificationFinishedGuard,
        ],
      },
      {
        path: 'profile',
        loadChildren: () => import('@app/pages/user/user.module').then((m) => m.UserModule),
        canActivate: [
          checkCollegeGuard,
          AuthGuard,
          signUpStepsVerificationFinishedGuard,
          signInStepsVerificationFinishedGuard,
        ],
      },
      {
        path: 'suspended',
        loadChildren: () => import('@app/pages/suspended/suspended.module').then((m) => m.SuspendedModule),
        canActivate: [checkCollegeGuard, signUpStepsVerificationFinishedGuard, signInStepsVerificationFinishedGuard],
      },
      {
        path: '',
        loadChildren: () => import('@app/pages/home/home.module').then((m) => m.HomeModule),
        canActivate: [checkCollegeGuard, signUpStepsVerificationFinishedGuard, signInStepsVerificationFinishedGuard],
        pathMatch: 'full',
        data: { main: true },
      },
      {
        path: 'auth',
        loadComponent: () => import('@app/pages/auth/auth-page.component').then((m) => m.AuthPageComponent),
        canActivate: [checkCollegeGuard, GuestGuard],
        children: [
          {
            path: '',
            redirectTo: 'login',
            pathMatch: 'full',
          },
          {
            path: 'login',
            loadComponent: () => import('@pages/auth/login/auth-login.component').then((m) => m.AuthLoginComponent),
            canActivate: [checkCollegeGuard, GuestGuard],
          },
          {
            path: 'register',
            loadComponent: () =>
              import('@pages/auth/register/auth-register.component').then((m) => m.AuthRegisterComponent),
            canActivate: [checkCollegeGuard, GuestGuard],
          },
          {
            path: 'login-new',
            redirectTo: 'login',
            pathMatch: 'full',
          },
        ],
      },
      {
        path: 'auth/forgot-password',
        loadChildren: () =>
          import('@app/pages/auth/forgot-password/forgot-password.module').then((m) => m.ForgotPasswordModule),
        canActivate: [checkCollegeGuard, GuestGuard],
      },
      {
        path: 'auth/enter-code',
        loadChildren: () => import('@app/pages/auth/enter-code/enter-code.module').then((m) => m.EnterCodeModule),
        canActivate: [checkCollegeGuard],
      },
      {
        path: 'terms',
        loadChildren: () => import('@app/pages/terms/terms.module').then((m) => m.TermsModule),
        canActivate: [checkCollegeGuard, AuthOptionalGuard],
      },
      {
        path: 'all-threads',
        loadChildren: () => import('@app/pages/all-threads/all-threads.module').then((m) => m.AllThreadsModule),
        canActivate: [
          checkCollegeGuard,
          AuthOptionalGuard,
          signUpStepsVerificationFinishedGuard,
          signInStepsVerificationFinishedGuard,
        ],
      },
      {
        path: 'all-students',
        loadChildren: () => import('@app/pages/all-students/all-students.module').then((m) => m.AllStudentsModule),
        canActivate: [
          checkCollegeGuard,
          ShowStudentsGuard,
          AuthOptionalGuard,
          signUpStepsVerificationFinishedGuard,
          signInStepsVerificationFinishedGuard,
        ],
      },

      {
        path: 'student-courses',
        loadChildren: () =>
          import('@pages/all-students/components/student-courses/student-courses.module').then(
            (m) => m.StudentCoursesModule,
          ),
        canActivate: [
          checkCollegeGuard,
          AdminGuard,
          signUpStepsVerificationFinishedGuard,
          signInStepsVerificationFinishedGuard,
        ],
      },
      {
        path: 'help',
        loadChildren: () => import('@app/pages/help/help.module').then((m) => m.HelpModule),
        canActivate: [checkCollegeGuard, signUpStepsVerificationFinishedGuard, signInStepsVerificationFinishedGuard],
      },
      {
        path: 'accessibility-help',
        loadChildren: () =>
          import('@app/pages/accessibility-help/accessibility-help.module').then((m) => m.AccessibilityHelpModule),
        canActivate: [checkCollegeGuard, signUpStepsVerificationFinishedGuard, signInStepsVerificationFinishedGuard],
      },
      {
        path: 'pdf-view',
        loadChildren: () => import('@app/pages/pdf-view/pdf-view.module').then((m) => m.PdfViewModule),
        canActivate: [checkCollegeGuard, signUpStepsVerificationFinishedGuard, signInStepsVerificationFinishedGuard],
      },
      {
        path: 'courses/:slug/pay',
        loadComponent: () =>
          import('@app/pages/payment/course/payment-course.component').then(
            (payment) => payment.PaymentCourseComponent,
          ),
        canActivate: [checkCollegeGuard],
      },
      {
        path: 'courses/:slug/payment-success',
        // eslint-disable-next-line max-len
        loadComponent: () =>
          import('@app/pages/payment/course/components/success/success.component').then(
            (payment) => payment.SuccessComponent,
          ),
        canActivate: [checkCollegeGuard],
      },
      {
        path: 'courses/:slug/:orderId/payment-error',
        loadComponent: () =>
          import('@app/pages/payment/course/components/error/error.component').then(
            (payment) => payment.ErrorComponent,
          ),
        canActivate: [checkCollegeGuard],
      },
      {
        path: 'bundles/:slug/pay',
        loadComponent: () =>
          import('@app/pages/payment/bundle/payment-bundle.component').then(
            (payment) => payment.PaymentBundleComponent,
          ),
        canActivate: [checkCollegeGuard],
      },
      {
        path: 'bundles/:slug/payment-success',
        // eslint-disable-next-line max-len
        loadComponent: () =>
          import('@app/pages/payment/bundle/components/success/success.component').then(
            (payment) => payment.SuccessComponent,
          ),
        canActivate: [checkCollegeGuard],
      },
      {
        path: 'bundles/:slug/:orderId/payment-error',
        loadComponent: () =>
          import('@app/pages/payment/bundle/components/error/error.component').then(
            (payment) => payment.ErrorComponent,
          ),
        canActivate: [checkCollegeGuard],
      },
      {
        path: ':type/:slug/thank-you',
        // eslint-disable-next-line max-len
        loadComponent: () =>
          import('@app/shared/components/thank-you-page/thank-you-page.component').then(
            (payment) => payment.ThankYouPageComponent,
          ),
        canActivate: [checkCollegeGuard],
      },
      {
        path: 'categories/:id',
        // eslint-disable-next-line max-len
        loadComponent: () =>
          import('@app/pages/category/category.component').then((category) => category.CategoryComponent),
        canActivate: [checkCollegeGuard, signUpStepsVerificationFinishedGuard, signInStepsVerificationFinishedGuard],
      },

      {
        path: 'cancel-payment',
        // eslint-disable-next-line max-len
        loadComponent: () =>
          import('@app/pages/cancel-payment/cancel-payment.component').then(
            (cancelPayment) => cancelPayment.CancelPaymentComponent,
          ),
        canActivate: [checkCollegeGuard],
      },
      {
        path: 'privacy-policy',
        // eslint-disable-next-line max-len
        loadComponent: () =>
          import('@app/pages/privacy-policy/privacy-policy.component').then(
            (privacyPolicy) => privacyPolicy.PrivacyPolicyComponent,
          ),
        canActivate: [checkCollegeGuard],
      },
      {
        path: 'student-certificate',
        // eslint-disable-next-line max-len
        loadComponent: () =>
          import('@app/pages/student-certificate/student-certificate-page.component').then(
            (studentCertificate) => studentCertificate.StudentCertificatePageComponent,
          ),
        canActivate: [checkCollegeGuard],
      },
      {
        path: 'disconnected-college',
        // eslint-disable-next-line max-len
        loadComponent: () =>
          import('@app/pages/disconnected-college/disconnected-college.component').then(
            (disconnectedCollege) => disconnectedCollege.DisconnectedCollegeComponent,
          ),
      },
      ...signInRoutes,
      ...signUpRoutes,
      ...provideCustomizerRoutes([...signInEditRoutes, ...signUpEditRoutes]),
    ],
  },

  {
    path: 'admin',
    canActivate: [
      checkCollegeGuard,
      AdminGuard,
      signUpStepsVerificationFinishedGuard,
      signInStepsVerificationFinishedGuard,
    ],
    loadChildren: () => import('@app/pages/admin/admin.module').then((m) => m.AdminModule),
  },
  { path: '**', loadChildren: () => import('@app/pages/not-found/not-found.module').then((m) => m.NotFoundModule) },
];
