import { Inject, Injectable } from '@angular/core';
import { WINDOW } from '@ng-web-apis/common';

@Injectable({ providedIn: 'root' })
export class PagesService {
  get onSitePage(): boolean {
    return !this.page('admin');
  }

  constructor(@Inject(WINDOW) readonly window: Window) {}

  page(name: string): boolean {
    let page = this.window.location.href.split('//');
    page = page[1].split('/');
    return page[1].includes(name);
  }

  paymentPage(name: string): boolean {
    let page = this.window.location.href.split('//');
    page = page[1].split('/');
    return page.includes(name);
  }
}
