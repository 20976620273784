import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FatherContactsComponent } from './father-contacts.component';
import { TranslateModule } from '@ngx-translate/core';
import { AirLibraryModule } from '@shared/modules/air/air-library/air-library.module';

@NgModule({
  declarations: [FatherContactsComponent],
  exports: [FatherContactsComponent],
  imports: [AirLibraryModule, CommonModule, TranslateModule],
})
export class FatherContactsModule {}
