import { Inject, Injectable } from '@angular/core';

import { CollegeSettings, SeoSettings, SeoSettingsToken } from 'libraries/college-settings';

@Injectable({
  providedIn: 'root',
})
export class FacebookPixelService {
  private loadOk = false;

  constructor(@Inject(SeoSettingsToken) readonly seoSettings: CollegeSettings<SeoSettings>) {}

  load(): void {
    if (!this.loadOk) {
      (function (f: any, b, e, v, n, t, s) {
        if (f.fbq) {
          return;
        }
        // @ts-ignore
        n = f.fbq = function () {
          // @ts-ignore
          n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
        };
        if (!f._fbq) {
          f._fbq = n;
        }
        // @ts-ignore
        n.push = n;
        // @ts-ignore
        n.loaded = !0;
        // @ts-ignore
        n.version = '2.0';
        // @ts-ignore
        n.queue = [];
        // @ts-ignore
        t = b.createElement(e);
        // @ts-ignore
        t.async = !0;
        // @ts-ignore
        t.src = v;
        // @ts-ignore
        s = b.getElementsByTagName(e)[0];
        // @ts-ignore
        s.parentNode.insertBefore(t, s);
      })(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js');
      (window as any).fbq.disablePushState = true;
      (window as any).fbq(
        'init',
        this.seoSettings.snapshot.facebook_pixel_id ?? this.seoSettings.snapshot.facebook_pixel_default_id,
      );
      (window as any).fbq('track', 'PageView');

      if (this.seoSettings.snapshot.facebook_pixel_track) {
        (window as any).fbq('track', this.seoSettings.snapshot.facebook_pixel_track);
      }

      this.loadOk = true;
    } else {
      (window as any).fbq('track', 'PageView');
      if (this.seoSettings.snapshot.facebook_pixel_track) {
        (window as any).fbq('track', this.seoSettings.snapshot.facebook_pixel_track);
      }
    }
  }
}
