import { Route } from '@angular/router';

export const signUpRoutes: Route[] = [
  {
    path: 'sign-up',
    loadComponent: () => import('./sign-up.component').then((c) => c.SignUpComponent),
  },
];

export const signUpEditRoutes: Route[] = [
  {
    path: 'sign-up',
    loadComponent: () => import('./sign-up-edit.component').then((c) => c.SignUpEditComponent),
  },
];
