import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, Input, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { WINDOW } from '@ng-web-apis/common';
import { DirectionService } from '@airy-ui/direction';

import { CallService } from './call.service';
import { CollegeInterface } from '@app/state/college/interfaces/college.interface';
import { AuthState } from '@state/auth/auth.state';
import { PhoneNumberService } from '@app/services/phone-number/phone-number.service';
import { PasteValueInInputService } from '@app/services/paste-value-in-input/paste-value-in-input.service';
import { DirectionEnum } from '@shared/enums/direction.enum';
import { SharedEnum } from '../../../../../../../../libraries/college-settings/src/lib/enum/shared.enum';
import { CollegeSettings, ContactsSettings, ContactsSettingsToken } from 'libraries/college-settings';

@Component({
  selector: 'yeda-fixed-call-form',
  templateUrl: './fixed-call-form.component.html',
  styleUrls: ['./fixed-call-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
@UntilDestroy()
export class FixedCallFormComponent implements OnInit {
  readonly DirectionEnum = DirectionEnum;
  readonly SharedEnum = SharedEnum;

  freeCallIconClass!: string;
  freeCallIconName!: string;
  loggedIn!: boolean;
  direction = this.directionService.direction;
  @Input() college?: CollegeInterface;
  callForm = new UntypedFormGroup({
    name: new UntypedFormControl(
      '',
      Validators.compose([Validators.required, Validators.pattern('[a-zA-Z \u0590-\u05fe]*')]),
    ),
    phone: new UntypedFormControl(
      '',
      Validators.compose([
        Validators.required,
        Validators.minLength(6),
        Validators.maxLength(18),
        Validators.pattern('^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\\s\\./0-9]*$'),
      ]),
    ),
    email: new UntypedFormControl(
      '',
      Validators.compose([
        Validators.minLength(5),
        Validators.pattern('^([a-z0-9_-]+.)*[a-z0-9_-]+@[a-z0-9_-]+(.[a-z0-9_-]+)*.[a-z]{2,6}$'),
      ]),
    ),
  });

  sent = false;

  tried_to_send = false;
  form_opened = false;
  inputFocusName = '';
  contactsPhone = '';

  constructor(
    private callService: CallService,
    public authService: AuthState,
    private directionService: DirectionService,
    public cdr: ChangeDetectorRef,
    public phoneNumberService: PhoneNumberService,
    private pasteValueInInputService: PasteValueInInputService,
    @Inject(WINDOW) readonly window: Window,
    @Inject(ContactsSettingsToken) readonly contactsSettings: CollegeSettings<ContactsSettings>,
  ) {}

  ngOnInit(): void {
    this.freeCallIconClass = 'v3';

    this.authService.state$.pipe(untilDestroyed(this)).subscribe(() => {
      this.loggedIn = this.authService.loggedIn;
      this.cdr.markForCheck();
    });

    this.contactsSettings.state$.pipe(untilDestroyed(this)).subscribe((settings) => {
      this.contactsPhone = settings?.contacts_phone;
      this.cdr.markForCheck();
    });
    if (this.college) {
      if ([99].includes(this.college.id)) {
        this.freeCallIconClass = 'v2';
        this.freeCallIconName = 'backcall-icon2';
      } else {
        this.freeCallIconClass = 'v3';
        this.freeCallIconName = 'free-consultation-v3';
      }
    }
  }

  toggleCallForm(): void {
    if (this.window.innerWidth < 951) {
      location.href = 'tel:' + this.contactsPhone.replace('+', '');
    } else {
      this.form_opened = !this.form_opened;
    }
  }

  sendCallRequest(): void {
    this.tried_to_send = true;
    if (this.callForm.invalid) {
      return;
    }
    const name = this.callForm.get('name')?.value;
    const phone = this.callForm.get('phone')?.value;
    const email = this.callForm.get('email')?.value;

    this.callService
      .send(name, phone, email)
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.sent = true;
        this.form_opened = false;
        this.cdr.markForCheck();
      });
  }

  closeForm(): void {
    this.form_opened = false;
  }

  pastePhone(event: ClipboardEvent): void {
    const phone = this.phoneNumberService.removeCode(this.pasteValueInInputService.pastePhoneNumber(event));
    this.callForm.controls.phone.setValue(phone);
  }
}
