<div
  class="visually-impaired"
  [ngClass]="{
    'visually-impaired-ltr': direction === DirectionEnum.LTR,
    'visually-impaired-rtl': direction === DirectionEnum.RTL
  }"
  airLayout="row"
  (clickOutside)="clickedOutside()"
>
  <button type="button" (click)="openOrClose()">
    <air-dynamic-icon size="xl" name="disabled-person" sprite="visually-impaired"></air-dynamic-icon>
  </button>
  <div class="menu-button" [class]="{'show': isShow()}">
    <div airTextStart airText2 airPadding="xs" airGridItem="12">{{ 'visually_impaired.title' | translate }}</div>
    <ul>
      <li>
        <button
          type="button"
          airGridItem="12"
          airLayout="row"
          airLayoutAlign="start center"
          airTextEnd
          airPadding="xs"
          (click)="plusFontSize()"
        >
          <air-dynamic-icon
            size="m"
            name="plus-font-size"
            color="primary"
            airMarginEnd="xxs"
            sprite="visually-impaired"
          ></air-dynamic-icon>
          {{ 'visually_impaired.plus_font_size' | translate }}
        </button>
      </li>
      <li>
        <button
          type="button"
          airGridItem="12"
          airLayout="row"
          airLayoutAlign="start center"
          airTextEnd
          airPadding="xs"
          (click)="minusFontSize()"
        >
          <air-dynamic-icon
            size="m"
            name="minus-font-size"
            color="primary"
            airMarginEnd="xxs"
            sprite="visually-impaired"
          ></air-dynamic-icon>
          {{ 'visually_impaired.minus_font_size' | translate }}
        </button>
      </li>
      <li>
        <button
          type="button"
          airGridItem="12"
          [class.active]="visuallyImpairedState.snapshot.grayscale"
          airLayout="row"
          airLayoutAlign="start center"
          airTextEnd
          airPadding="xs"
          (click)="grayscale()"
        >
          <air-dynamic-icon
            size="m"
            name="grayscale"
            color="primary"
            airMarginEnd="xxs"
            sprite="visually-impaired"
          ></air-dynamic-icon>
          {{ 'visually_impaired.grayscale' | translate }}
        </button>
      </li>
      <li>
        <button
          type="button"
          airGridItem="12"
          [class.active]="visuallyImpairedState.snapshot.high_contrast"
          airLayout="row"
          airLayoutAlign="start center"
          airTextEnd
          airPadding="xs"
          (click)="highContrast()"
        >
          <air-dynamic-icon
            size="m"
            name="high-contrast"
            color="primary"
            airMarginEnd="xxs"
            sprite="visually-impaired"
          ></air-dynamic-icon>
          {{ 'visually_impaired.high_contrast' | translate }}
        </button>
      </li>
      <li>
        <button
          type="button"
          airGridItem="12"
          [class.active]="visuallyImpairedState.snapshot.negative_contrast"
          airLayout="row"
          airLayoutAlign="start center"
          airTextEnd
          airPadding="xs"
          (click)="negativeContrast()"
        >
          <air-dynamic-icon
            size="m"
            name="negative-contrast"
            color="primary"
            airMarginEnd="xxs"
            sprite="visually-impaired"
          ></air-dynamic-icon>
          {{ 'visually_impaired.negative_contrast' | translate }}
        </button>
      </li>
      <li>
        <button
          type="button"
          airGridItem="12"
          [class.active]="visuallyImpairedState.snapshot.light_background"
          airLayout="row"
          airLayoutAlign="start center"
          airTextEnd
          airPadding="xs"
          (click)="lightBackground()"
        >
          <air-dynamic-icon
            size="m"
            name="light-background"
            color="primary"
            airMarginEnd="xxs"
            sprite="visually-impaired"
          ></air-dynamic-icon>
          {{ 'visually_impaired.light_background' | translate }}
        </button>
      </li>
      <li>
        <button
          type="button"
          airGridItem="12"
          [class.active]="visuallyImpairedState.snapshot.links_underline"
          airLayout="row"
          airLayoutAlign="start center"
          airTextEnd
          airPadding="xs"
          (click)="linksUnderline()"
        >
          <air-dynamic-icon
            size="m"
            name="links-underline"
            color="primary"
            airMarginEnd="xxs"
            sprite="visually-impaired"
          ></air-dynamic-icon>
          {{ 'visually_impaired.links_underline' | translate }}
        </button>
      </li>

      <li>
        <button
          type="button"
          airGridItem="12"
          [class.active]="visuallyImpairedState.snapshot.readable_font"
          airLayout="row"
          airLayoutAlign="start center"
          airTextEnd
          airPadding="xs"
          (click)="readableFont()"
        >
          <air-dynamic-icon
            size="m"
            name="readable-font"
            color="primary"
            airMarginEnd="xxs"
            sprite="visually-impaired"
          ></air-dynamic-icon>
          {{ 'visually_impaired.readable_font' | translate }}
        </button>
      </li>
      <li>
        <button
          type="button"
          airGridItem="12"
          airLayout="row"
          airLayoutAlign="start center"
          airTextEnd
          airPadding="xs"
          (click)="reset()"
        >
          <air-dynamic-icon
            size="m"
            name="reset"
            color="primary"
            airMarginEnd="xxs"
            sprite="visually-impaired"
          ></air-dynamic-icon>
          {{ 'visually_impaired.reset' | translate }}
        </button>
      </li>
      <li>
        <button
          type="button"
          airGridItem="12"
          airLayout="row"
          airLayoutAlign="start center"
          airTextEnd
          airPadding="xs"
          (click)="showHelp()"
        >
          <air-dynamic-icon size="m" name="question" color="primary" airMarginEnd="xxs"></air-dynamic-icon>
          {{ 'visually_impaired.help' | translate }}
        </button>
      </li>
    </ul>
  </div>
</div>
