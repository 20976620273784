import { inject, Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { LOCAL_STORAGE } from '@ng-web-apis/common';
import { tap } from 'rxjs/operators';
import { firstValueFrom } from 'rxjs';
import { DirectionService } from '@airy-ui/direction';
import { CollegeState } from '../../state/college/college.state';
import { CollegeInterface } from '../../state/college/interfaces/college.interface';
import { SeoService } from '../../shared/services/seo/seo.service';
import {
  AuthSettings,
  AuthSettingsRepository,
  CollegeSettings,
  CollegeSettingsService,
  HeaderSettings,
  HeaderSettingsToken,
  Language,
} from 'libraries/college-settings';
import { DirectionEnum } from '../../shared/enums/direction.enum';
import { PreloadAction } from '../../shared/modules/air/air-modules/actions/interfaces/preload.action';
import { Directionality } from '@angular/cdk/bidi';
import { DOCUMENT } from '@angular/common';

@Injectable()
export class PreloadCollegeAction implements PreloadAction {
  readonly document = inject(DOCUMENT);
  constructor(
    private collegeState: CollegeState,
    private settingsService: CollegeSettingsService,
    private seoService: SeoService,
    private directionService: DirectionService,
    private directionality: Directionality,
    private router: Router,
    @Inject(LOCAL_STORAGE) readonly localStorage: Storage,
    @Inject(AuthSettingsRepository) readonly authSettings: CollegeSettings<AuthSettings>,
    @Inject(HeaderSettingsToken) readonly headerSettings: CollegeSettings<HeaderSettings>,
  ) {}

  async preload(): Promise<CollegeInterface | undefined> {
    const t = await firstValueFrom(
      this.collegeState.fetch().pipe(tap(this.setDirection.bind(this)), tap(this.setDefaultSeo.bind(this))),
    );
    return t;
  }

  private setDirection(): void {
    const dir =
      this.settingsService.defaultSettings.default_language === Language.HE ||
      this.settingsService.defaultSettings.default_language === Language.HE_312 ||
      this.settingsService.defaultSettings.default_language === Language.AR
        ? DirectionEnum.RTL
        : DirectionEnum.LTR;
    this.directionService.set(dir);
    this.document.dir = dir;
    this.directionality.change.emit(dir);
  }

  private setDefaultSeo(college: CollegeInterface): void {
    this.seoService.setDefaultTitle(college.seo.title);
    this.seoService.setDefaultDescription(college.seo.description);
  }
}
