import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { firstValueFrom } from 'rxjs';
import { StudentSettingsToken } from 'libraries/college-settings';

@Injectable({
  providedIn: 'root',
})
export class ShowStudentsGuard implements CanActivate {
  readonly studentSettings = inject(StudentSettingsToken);

  constructor() {}

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    await firstValueFrom(this.studentSettings.fetch());
    return (await firstValueFrom(this.studentSettings.state$)).student_show_everywhere;
  }
}
