import { inject, Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { tap } from 'rxjs/operators';
import { merge } from 'lodash-es';
import { CollegeEnvironmentRepository } from '@core/state/college-environment.state';
import { backend_environment_default } from '@env/backend-environment-default';
import { CollegeSettingsService, LanguageSettings } from 'libraries/college-settings';
import { PreloadAction } from '@shared/modules/air/air-modules/actions/interfaces/preload.action';
import { college$, CollegeData } from 'libraries/env';

@Injectable()
export class PresetCollegeAction implements PreloadAction {
  readonly languageSettings = inject(LanguageSettings);

  constructor(
    private collegeEnvironmentState: CollegeEnvironmentRepository,
    private settingService: CollegeSettingsService,
  ) {}

  preload(): Promise<any> {
    return firstValueFrom(
      college$.pipe(
        tap((response) => {
          this.settingService.defaultSettings = response.settings;
          this.languageSettings.patch({ default_language: response.settings.default_language });
          this.setCollegeEnvironment(response);
        }),
      ),
    );
  }

  setCollegeEnvironment(collegeData: CollegeData): void {
    if (collegeData.college_environments) {
      merge(backend_environment_default, collegeData.college_environments);
      this.collegeEnvironmentState.patch({
        collegeEnvironment: backend_environment_default,
        college_id: collegeData.college_id,
      });
    }
  }
}
