import { OnRouteEndAction } from '@shared/modules/air/air-modules/actions';
import { NavigationEnd } from '@angular/router';
import { Injectable } from '@angular/core';
import { AuthState } from '@state/auth/auth.state';
import { ModalRequiredFieldService } from '@shared/modals/modal-required-field/service/modal-required-field.service';
import { RequiredFieldState } from '@shared/modals/modal-required-field/shared/state/required-field.state';

@Injectable()
export class ShowAdditionalVerificationAction implements OnRouteEndAction {
  constructor(
    private authState: AuthState,
    private modalRequiredFieldService: ModalRequiredFieldService,
  ) {}

  onRouteEnd(event: NavigationEnd): void {
    // this.showAdditionalVerification();
  }

  private showAdditionalVerification(): void {
    if (this.authState.loggedIn) {
      this.modalRequiredFieldService.openAfterLogin();
    }
  }
}
