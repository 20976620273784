import { BrowserModule } from '@angular/platform-browser';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CoreModule } from '@app/core/core.module';
import { RouterModule } from '@angular/router';
import { routes } from '@app/core/routes/routes';
import { NgxMaskModule } from 'ngx-mask';
import { VisuallyImpairedModule } from '@app/shared/modules/fixed-labels/visually-impaired/visually-impaired.module';
import { FatherContactsModule } from '@app/shared/modules/fixed-labels/father-contacts/father-contacts.module';
import { AirLibraryModule } from '@shared/modules/air/air-library/air-library.module';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { ModalModule } from '@shared/modals/modal.module';
import { SharedModule } from '@shared/shared.module';
import { WidgetWhatsAppComponent } from '@shared/modules/widget/widget-whats-app/widget-whats-app.component';
import { NewLoaderComponent } from '@shared/components/loader/new-loader.component';
import { FixedCallFormModule } from '@shared/modules/fixed-labels/fixed-call-form/fixed-call-form.module';
// import function to register Swiper custom elements
import { register } from 'swiper/element/bundle';
import { WlAdminMenuComponent } from 'libraries/wl-admin-menu';
import { MainLayoutComponent } from '@shared/layout/main-layout/main-layout.component';
import { WidgetSupportComponent } from '@shared/modules/widget/widget-support/widget-support.component';
// register Swiper custom elements
register();

@NgModule({
  providers: [provideHttpClient(withInterceptorsFromDi())],
  declarations: [AppComponent],
  imports: [
    TranslateModule,
    AirLibraryModule,
    BrowserModule,
    RouterModule.forRoot(routes, { scrollPositionRestoration: 'top', anchorScrolling: 'enabled' }),
    FixedCallFormModule,
    BrowserAnimationsModule,
    CoreModule,
    NgxMaskModule.forRoot(),
    VisuallyImpairedModule,
    FatherContactsModule,
    ModalModule,
    FormsModule,
    SharedModule,
    WidgetWhatsAppComponent,
    NewLoaderComponent,
    WlAdminMenuComponent,
    WidgetSupportComponent,
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
