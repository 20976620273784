import { Injectable } from '@angular/core';
import { NavigationStart } from '@angular/router';
import { OnRouteStartAction } from '@shared/modules/air/air-modules/actions/interfaces/on-route-start.action';
import { pages } from 'libraries/college-pages';

@Injectable()
export class RoutesHistoryAction implements OnRouteStartAction {
  onRouteStart(event: NavigationStart): void {
    let previousUrl = localStorage.getItem('currentUrl');
    localStorage.setItem('currentUrl', event.url);
    if (previousUrl) {
      if (previousUrl === '/my-courses/all') {
        previousUrl = '/my-studies/my-courses';
      }

      localStorage.setItem('previousUrl', previousUrl);
      const prevUrlBase = previousUrl.split('?')[0];
      if (![pages.auth.login, pages.auth.register, pages.auth.registerNew, pages.auth.loginNew].includes(prevUrlBase)) {
        localStorage.setItem('beforeAuthUrl', previousUrl);
      }
    }
  }
}
