import { ErrorHandler, inject, Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { LoggerService } from 'libraries/logger';
import { AuthRepository } from 'libraries/auth';

function getReadableErrorMessage(error: unknown): string {
  if (error instanceof Error) {
    // Extract the message and the first line of the stack trace
    const message = error.message;
    const stack = error.stack?.split('\n')[1]?.trim();

    return stack ? `${message} at ${stack}` : message;
  }
  else {
    // If it's not an Error object, just return it as is
    return String(error);
  }
}

@Injectable()
export class GlobalErrorHandlerService implements ErrorHandler {
  #logger = inject(LoggerService);
  #authRepository = inject(AuthRepository);

  handleError(error: Error) {
    console.error('error handler:', error);
    try {
      this.#logger
        .log({
          error,
          name: error.name,
          message: getReadableErrorMessage(error),
          user: this.#authRepository.loggedIn()
            ? {
                id: this.#authRepository.snapshot.user.id,
                email: this.#authRepository.snapshot.user.email,
              }
            : 'Guest',
          url: location.href,
        })
        .subscribe();
    } catch (e) {
      console.error('Logger error', e);
    }
    if (!navigator.onLine) {
      return;
    }
    if (error instanceof HttpErrorResponse) {
      const message = error.message;
      console.log('message', message);
      if (error.status === 401 &&  this.#authRepository.snapshot?.auth?.access_token) {
        this.#authRepository.logout().then();
      }
    }
  }
}
