import { APP_BOOTSTRAP_LISTENER, ComponentRef, Provider } from '@angular/core';
import { FacebookPixelService } from '@core/services/facebook-pixel/facebook-pixel.service';
import { NavigationEnd, Router } from '@angular/router';

export const FACEBOOK_PIXEL_PROVIDER: Provider = {
  provide: APP_BOOTSTRAP_LISTENER,
  multi: true,
  useFactory: FacebookPixelRouterInitializer,
  deps: [FacebookPixelService, Router],
};

export function FacebookPixelRouterInitializer($fpService: FacebookPixelService, $router: Router) {
  return async (c: ComponentRef<any>) => {
    $router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        $fpService.load();
      }
    });
  };
}
