import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { ModalComponent } from '@airy-ui/modal';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter } from 'rxjs/operators';
import { DeviceInfoInterface } from '../../../pages/edit-user/state/interfaces/device-info.interface';
import { UserDevicesState } from '../../../pages/edit-user/state/user-devices.state';
import { CheckDevicesInterface } from '../../../pages/edit-user/state/interfaces/check-devices.interface';
import { AuthState } from '../../../state/auth/auth.state';
import { ModalRequiredFieldService } from '../modal-required-field/service/modal-required-field.service';

@Component({
  selector: 'yeda-modal-devices',
  templateUrl: './modal-devices.component.html',
  styleUrls: ['./modal-devices.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
@UntilDestroy()
export class ModalDevicesComponent implements OnInit {
  statuses = ['closed', 'open'];
  devices!: DeviceInfoInterface[];
  myDevice!: DeviceInfoInterface;
  openDevicesNumber!: number;
  tooManyOpenedDevices!: boolean;
  isClosedDevice!: boolean;
  checkDeviceInfo!: CheckDevicesInterface;
  maxDevicesNumber!: number;
  isLoading = false;
  @ViewChild(ModalComponent, { static: true }) modal!: ModalComponent;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private userDevicesState: UserDevicesState,
    private cdr: ChangeDetectorRef,
    private modalRequiredFieldService: ModalRequiredFieldService,
    private authState: AuthState
  ) {
  }

  ngOnInit(): void {
    this.router.events
      .pipe(
        filter((e) => e instanceof NavigationEnd && this.authState.loggedIn),
        untilDestroyed(this)
      )
      .subscribe(() => {
        if (this.route.snapshot.queryParamMap.get('devices')) {
          this.userDevicesState.checkDevices(this.authState.user.id).subscribe((response) => {
            this.checkDeviceInfo = response;
            this.devices = response.devices;
            this.myDevice = response.myDevice;
            this.isClosedDevice = this.checkDeviceInfo.isClosedDevice;
            this.openDevicesNumber = this.devices.length;

            if (this.checkTooManyOpenedDevicesOrClosed()) {
              setTimeout(() => this.modal.open(), 500);
            }
          });
        }
      });
  }

  checkTooManyOpenedDevicesOrClosed(): boolean {
    this.tooManyOpenedDevices =
      this.userDevicesState.checkDevicesInfo.limitDevicesCount &&
      this.openDevicesNumber >= this.userDevicesState.checkDevicesInfo.maxDevicesNumber;
    this.maxDevicesNumber = this.userDevicesState.checkDevicesInfo.maxDevicesNumber;
    return this.tooManyOpenedDevices || this.isClosedDevice;
  }

  toggleDevice(id: number, index: number): void {
    this.isLoading = true;
    this.userDevicesState
      .toggleDevice(id, index)
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.devices[index].status = this.devices[index].status === null ? 0 : 1 - this.devices[index].status;
        if (this.devices[index].status === 1) {
          this.openDevicesNumber += 1;
        }
        else {
          this.openDevicesNumber -= 1;
        }
        this.checkTooManyOpenedDevicesOrClosed();
        this.isLoading = false;
        this.cdr.detectChanges();
      });
  }

  closeDevice(id: number, index: number): void {
    this.isLoading = true;
    this.userDevicesState
      .closeDevice(id, index)
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.devices[index].status = 0;
        this.openDevicesNumber -= 1;
        this.checkTooManyOpenedDevicesOrClosed();
        this.isLoading = false;
        this.cdr.detectChanges();
      });
  }

  onClose(): void {
    if (this.devices && (this.tooManyOpenedDevices || this.isClosedDevice)) {
      setTimeout(() => this.authState.logout(), 1000);
    }
  }

  closeThisDeviceAndLogout(): void {
    this.userDevicesState
      .toggleCurrentDevice()
      .pipe(untilDestroyed(this))
      .subscribe((new_status) => {
        if (new_status === 0) {
          this.modal.close();
          this.authState.logout();
        }
      });
  }

  logout(): void {
    this.modal.close();
    this.authState.logout();
  }

  close(): void {
    this.modal.close();

    if (this.authState.loggedIn) {
      this.modalRequiredFieldService.openAfterCheckDevice();
    }
  }

  getTimeElapsed(lastSeenString: string): { quantity: number; type: string } {
    const lastSeen = new Date(lastSeenString);
    const now = new Date();

    const elapsedMilliseconds = now.getTime() - lastSeen.getTime();
    const elapsedSeconds = Math.floor(elapsedMilliseconds / 1000);
    const elapsedMinutes = Math.floor(elapsedSeconds / 60);
    const elapsedHours = Math.floor(elapsedMinutes / 60);
    const elapsedDays = Math.floor(elapsedHours / 24);
    const elapsedMonths = Math.floor(elapsedDays / 30);

    if (elapsedMonths > 0) {
      return { quantity: elapsedMonths, type: 'device.time_months' };
    }
    else if (elapsedDays > 0) {
      return { quantity: elapsedDays, type: 'device.time_days' };
    }
    else if (elapsedHours > 0) {
      return { quantity: elapsedHours, type: 'device.time_hours' };
    }
    else if (elapsedMinutes > 0) {
      return { quantity: elapsedMinutes, type: 'device.time_minutes' };
    }
    else {
      return { quantity: elapsedSeconds, type: 'device.time_seconds' };
    }
  }
}
