import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VisuallyImpairedComponent } from './visually-impaired.component';
import { TranslateModule } from '@ngx-translate/core';
import { AirLibraryModule } from '../../air/air-library/air-library.module';
import { ClickOutsideModule } from '../../click-outside/click-outside.module';

@NgModule({
  declarations: [VisuallyImpairedComponent],
  exports: [VisuallyImpairedComponent],
  imports: [AirLibraryModule, CommonModule, TranslateModule, RouterModule, ClickOutsideModule]
})
export class VisuallyImpairedModule {}
