import { Inject, Injectable } from '@angular/core';
import { lastValueFrom, of } from 'rxjs';
import { CollegeSettings, SeoSettings, SeoSettingsToken } from 'libraries/college-settings';
import { PreloadAction } from '@shared/modules/air/air-modules/actions/interfaces/preload.action';

@Injectable()
export class PreloadSeoSettingsAction implements PreloadAction {
  constructor(@Inject(SeoSettingsToken) readonly seoSettings: CollegeSettings<SeoSettings>) {}

  preload(): Promise<any> {
    return lastValueFrom(this.seoSettings.fetch());
  }
}
