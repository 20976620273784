@if (authRepository?.user()?.permissions?.includes('access_old_admin_panel_users') || containsTeacherOrHigher()) {
  <button color="default" mat-fab [matMenuTriggerFor]="menu">
    <mat-icon>more_vert</mat-icon>
  </button>

  <mat-menu #menu>
    @if (authRepository.admin()) {
      @if (authSettingsRepository.state().use_new_auth && bp.tabletAndBigger()) {
        <a mat-menu-item [routerLink]="[{ outlets: { customizer: ['customize'] } }]">
          <mat-icon>tune</mat-icon>
          {{ 'wl_admin_menu.customize' | translate }}
        </a>
      }
      <a target="_blank" href="/admin/settings" mat-menu-item>
        <mat-icon>admin_panel_settings</mat-icon>
        {{ 'wl_admin_menu.settings' | translate: { default: 'Settings' } }}
      </a>
      @for (page of dynamicPages(); track page.url) {
        <a target="_blank" [href]="page.url" mat-menu-item>
          <mat-icon>{{ page.icon }}</mat-icon>
          {{ 'wl_admin_menu.' + page.name | translate: { default: page.name } }}
        </a>
      }
    }

    @if (containsTeacherOrHigher() || authRepository?.user()?.permissions?.includes('access_old_admin_panel_users')) {
      <a mat-menu-item target="_blank" [href]="usersPath()">
        <mat-icon>group_add</mat-icon>
        {{ 'wl_admin_menu.users' | translate }}</a
      >
    }

    @if (containsTeacherOrHigher()) {
      <button mat-menu-item (click)="addAccount()">
        <mat-icon>person_add</mat-icon>
        {{ 'wl_admin_menu.add_account' | translate: { default: 'Add Account' } }}
      </button>

      @if (accounts().length) {
        <mat-divider />
        @for (account of accounts(); track account.user.id) {
          @if (account.user.id !== authRepository.user().id) {
            <button (click)="switchTo(account)" mat-menu-item>
              <yeda-user-avatar
                class="mat-icon"
                matMenuItemIcon
                style="min-width: 0"
                [username]="account.user.name"
                [avatar]="account.user.image"
              />
              {{ account.user.username }}
            </button>
          }
        }
      }

    }
  </mat-menu>
}
