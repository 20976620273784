import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DirectionService } from '@airy-ui/direction';

@Component({
  selector: 'yeda-widget-whats-app',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './widget-whats-app.component.html',
  styleUrls: ['./widget-whats-app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WidgetWhatsAppComponent {
  direction = this.directionService.direction;

  constructor(private directionService: DirectionService) {}
}
