import { Injectable } from '@angular/core';
import { StringObject } from '@airy-ui/cdk';
import { TranslationsService } from '@state/translations/translations.service';
import { lastValueFrom } from 'rxjs';
import { PreloadAction } from '@shared/modules/air/air-modules/actions/interfaces/preload.action';

@Injectable()
export class PreloadTranslationsAction implements PreloadAction {
  constructor(private translationsService: TranslationsService) {}

  preload(): Promise<StringObject | undefined> {
    return lastValueFrom(this.translationsService.fetchAndSetTranslations());
  }
}
