import { BeforeUnloadService } from './before-unload.service';
import { HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class BeforeUnloadHttpRequestsService {
  constructor(private beforeUnloadService: BeforeUnloadService) {}

  add(req: HttpRequest<any>): void {
    this.beforeUnloadService.add(this.key(req));
  }

  remove(req: HttpRequest<any>): void {
    this.beforeUnloadService.remove(this.key(req));
  }

  key(req: HttpRequest<any>) {
    return req.method + req.urlWithParams;
  }

  isChangeableRequest(method: string): boolean {
    return method === 'POST' || method === 'PUT' || method === 'DELETE' || method === 'PATH';
  }
}
