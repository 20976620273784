@if (courseState.state$ | async; as courseState) {
  @if (wbHookSettings.state$ | async; as webHookSettings) {
    @if (showWidget(courseState, webHookSettings)) {
      <div
        class="support-options"
        [ngClass]="{'support-ltr': direction === 'ltr','support-rtl': direction === 'rtl' }"
        (click)="openDialog()"
      >

        <button
          [ngClass]="{'support-ltr': direction === 'ltr','support-rtl': direction === 'rtl'}"
        >
          <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M17 1.41675L4.25 7.08341V15.5834C4.25 23.4459 9.69 30.7984 17 32.5834C24.31 30.7984 29.75 23.4459 29.75 15.5834V7.08341L17 1.41675ZM17 7.08341C18.1272 7.08341 19.2082 7.53118 20.0052 8.32821C20.8022 9.12524 21.25 10.2062 21.25 11.3334C21.25 12.4606 20.8022 13.5416 20.0052 14.3386C19.2082 15.1356 18.1272 15.5834 17 15.5834C15.8728 15.5834 14.7918 15.1356 13.9948 14.3386C13.1978 13.5416 12.75 12.4606 12.75 11.3334C12.75 10.2062 13.1978 9.12524 13.9948 8.32821C14.7918 7.53118 15.8728 7.08341 17 7.08341ZM24.2675 24.0834C22.5533 26.7042 19.9892 28.6734 17 29.6367C14.0108 28.6734 11.4467 26.7042 9.7325 24.0834C9.25083 23.3751 8.84 22.6667 8.5 21.9159C8.5 19.5784 12.3392 17.6659 17 17.6659C21.6608 17.6659 25.5 19.5359 25.5 21.9159C25.16 22.6667 24.7492 23.3751 24.2675 24.0834Z"
              fill="white"
            />
          </svg>
        </button>


        <div class="support-text">
          {{ 'support_course.widget_title' | translate }}
        </div>
      </div>
    }
  }
}