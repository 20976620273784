import { Inject, Injectable } from '@angular/core';
import { WINDOW } from '@ng-web-apis/common';

@Injectable({
  providedIn: 'root',
})
export class WindowLocationService {
  get queryParams(): { [key: string]: string } {
    const queryParamsString: string = this.window.location.search.split('?')[1];
    const params: string[] = queryParamsString ? queryParamsString.split('&') : [];
    const queryParams: { [key: string]: string } = {};

    params.forEach((value) => {
      const param = value.split('=');
      const key = param[0];
      queryParams[key] = param[1];
    });

    return queryParams;
  }

  constructor(@Inject(WINDOW) readonly window: Window) {}

  hasQueryParam(name: string): boolean {
    const queryParams: { [key: string]: string } = this.queryParams;
    return Boolean(queryParams[name]);
  }
}
