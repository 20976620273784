import { ChangeDetectionStrategy, Component, OnInit, signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialogClose, MatDialogContent, MatDialogTitle } from '@angular/material/dialog';
import { MatButton, MatIconButton } from '@angular/material/button';
import { MatError, MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MAT_RADIO_DEFAULT_OPTIONS, MatRadioButton, MatRadioGroup } from '@angular/material/radio';
import { ReactiveFormsModule, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { pages } from 'libraries/college-pages';
import {
  SupportRequestFormService
} from '@shared/modals/modal-support/shared/support-request-form.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { MatIcon } from '@angular/material/icon';
import { CourseState } from '@pages/course/shared/state/course.state';

@Component({
  selector: 'yeda-modal-support',
  standalone: true,
  imports: [
    CommonModule,
    MatDialogTitle,
    MatButton,
    MatDialogClose,
    MatDialogContent,
    MatError,
    MatFormField,
    MatInput,
    MatLabel,
    MatRadioButton,
    MatRadioGroup,
    ReactiveFormsModule,
    TranslateModule,
    MatIcon,
    MatIconButton
  ],
  templateUrl: './modal-support.component.html',
  styleUrl: './modal-support.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{
    provide: MAT_RADIO_DEFAULT_OPTIONS,
    useValue: { color: 'primary' }
  }]

})
@UntilDestroy()
export class ModalSupportComponent implements OnInit {
  readonly pages = pages;

  isSuccess = signal<boolean>(false);
  isLoading = signal<boolean>(false);

  form = new UntypedFormGroup({
    course_id: new UntypedFormControl(null),
    type: new UntypedFormControl(null, [Validators.required]),
    theme: new UntypedFormControl(null, [Validators.required]),
    message: new UntypedFormControl(null, [Validators.required])
  });

  constructor(
    private supportRequestFormService: SupportRequestFormService,
    private courseState: CourseState,
  ) {
  }

  ngOnInit(): void {
  }

  submit(): void {
    if (this.courseState.snapshot.courseId) {
      this.isLoading.set(true);
      this.form.controls.course_id.setValue(this.courseState.snapshot.courseId);
      this.supportRequestFormService.submit(this.form.value)
        .pipe(untilDestroyed(this))
        .subscribe((response) => {
          if (response.status === 'success') {
            this.form.reset();
            this.isSuccess.set(true);
          }
          this.isLoading.set(false);
        });
    }
  }
}

