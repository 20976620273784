import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { Direction } from '@angular/cdk/bidi';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DirectionService } from '@airy-ui/direction';

import { CollegeState } from '@state/college/college.state';
import { AuthState } from '@state/auth/auth.state';
import { PagesService } from '@shared/services/pages/pages.service';
import {
  BasicSettings,
  BasicSettingsToken,
  CollegeSettings,
  HeaderSettings,
  HeaderSettingsToken,
} from 'libraries/college-settings';
import { map } from 'rxjs/operators';
import { BreakpointName } from '@airy-ui/cdk';

@Component({
  selector: 'yeda-father-contacts',
  templateUrl: './father-contacts.component.html',
  styleUrls: ['./father-contacts.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
@UntilDestroy()
export class FatherContactsComponent implements OnInit {
  direction!: Direction;
  bSettings: BasicSettings | undefined;
  hSettings: HeaderSettings | undefined;

  readonly BreakpointName = BreakpointName;

  constructor(
    public collegeState: CollegeState,
    public authState: AuthState,
    public pagesService: PagesService,
    public directionService: DirectionService,
    private cdr: ChangeDetectorRef,
    @Inject(BasicSettingsToken) readonly basicSettings: CollegeSettings<BasicSettings>,
    @Inject(HeaderSettingsToken) readonly headerSettings: CollegeSettings<HeaderSettings>,
  ) {}

  ngOnInit(): void {
    this.direction = this.directionService.direction;

    this.basicSettings.state$.pipe(untilDestroyed(this)).subscribe((settings) => {
      this.bSettings = settings;
      this.cdr.markForCheck();
    });

    this.headerSettings.state$
      .pipe(
        map((settings) => {
          this.hSettings = settings;
        }),
      )
      .subscribe(() => this.cdr.markForCheck());
  }
}
