import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { getCollege } from './get-college';
import { setCollege } from 'libraries/env';
import { enableElfProdMode } from '@ngneat/elf';

if (environment.production) {
  enableProdMode();
  enableElfProdMode();
}

getCollege().then((collegeData) => {
  setCollege(collegeData);
  platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch((err) => console.error(err));
});
