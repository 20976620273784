<air-modal #modal="modal" panelClass="devices-panel" (onModalClose)="onClose()" [closeOnBackdropClick]="false">
  <air-modal-close size="s" *ngIf="checkDeviceInfo?.isClosedDevice"></air-modal-close>
  <div
    header
    airTextCenter
    class="devices-modal-header"
    *ngIf="checkDeviceInfo?.isClosedDevice"
    ltSm.airMarginTop="-l"
    ltSm.airMarginEnd="-l"
    ltSm.airMarginStart="-l"
    ltSm.airPadding="m"
    gtXs.airMarginTop="-l"
    gtXs.airMarginEnd="-l"
    gtXs.airMarginStart="-l"
    gtXs.airPadding="l"
    gtXs.airPaddingTop="xl"
    ltSm.airPaddingTop="xl"
  >
    <h3>{{ 'devices.exceeded_devices_number' | translate }}</h3>
  </div>

  <div *ngIf="checkDeviceInfo?.isClosedDevice">
    <h5>{{ 'devices.device_is_closed' | translate }}</h5>
  </div>

  <div
    header
    airTextCenter
    class="devices-modal-header"
    *ngIf="!checkDeviceInfo?.isClosedDevice"
    ltSm.airMarginTop="-l"
    ltSm.airMarginEnd="-l"
    ltSm.airMarginStart="-l"
    ltSm.airPadding="m"
    gtXs.airMarginTop="-l"
    gtXs.airMarginEnd="-l"
    gtXs.airMarginStart="-l"
    gtXs.airPadding="l"
  >
    <h3>{{ 'devices.exceeded_devices_number' | translate }}</h3>
  </div>
  <div *ngIf="!checkDeviceInfo?.isClosedDevice">
    <h4 airTextCenter ltSm.airMarginBottom="m" airMarginBottom="l">
      {{ 'devices.devices_limit_exceeded' | translate: { max_number: maxDevicesNumber } }}
    </h4>
    <div class="devices" airLayout="column">
      <div class="devices-header" airLayout="row" airColor="text-secondary">
        <div class="device-info desktop-only" airLayout="row" ltSm.airLayout="column">
          <div class="device-type cell">{{ 'device.type' | translate }}</div>
          <div class="device-platform cell">{{ 'device.platform' | translate }}</div>
          <div class="device-browser cell">{{ 'device.browser' | translate }}</div>
          <div id="last_session" class="device-time cell">{{ 'device.last_session' | translate }}</div>
        </div>
        <div class="device-info mobile-only" airLayout="row" ltSm.airLayout="column">
          <div class="device-type cell">{{ 'device.device' | translate }}</div>
        </div>
        <div class="device-status cell">{{ 'device.status' | translate }}</div>
        <div class="actions"></div>
      </div>
      <div class="devices-row" airLayout="row" *ngIf="myDevice">
        <div id="current-my-device" class="device-info" airLayout="row" ltSm.airLayout="column">
          <div class="device-type cell">{{ myDevice.device_type }} {{ myDevice.device }}</div>
          <div class="device-platform cell">{{ myDevice.platform }} {{ myDevice.platform_version }}</div>
          <div class="device-browser cell">{{ myDevice.browser }} {{ myDevice.browser_version }}</div>
          <div id="this_device" class="device-time cell">{{ 'device.this_device' | translate }}</div>
        </div>
        <div class="device-status cell" airColor="text-secondary">
          <span *ngIf="tooManyOpenedDevices">{{ 'device.blocked' | translate }}</span>
          <span *ngIf="!tooManyOpenedDevices">{{ 'device.open' | translate }}</span>
        </div>
      </div>

      <div class="devices-row" *ngFor="let device of devices; let i = index" airLayout="row">
        <div class="device-info" airLayout="row" ltSm.airLayout="column">
          <div class="device-type cell">{{ device.device_type }} {{ device.device }}</div>
          <div class="device-platform cell">{{ device.platform }} {{ device.platform_version }}</div>
          <div class="device-browser cell">{{ device.browser }} {{ device.browser_version }}</div>
          <div id="getTime" class="device-time cell">
            {{ getTimeElapsed(device.last_seen.toString()).type | translate: { number: getTimeElapsed(device.last_seen.toString()).quantity } }}
          </div>
        </div>
        <div class="device-status cell">{{ statuses[device.status === null ? 1 : device.status] }}</div>
        <button
          airPaddingEnd="none"
          air-button
          fill="clear"
          color="danger"
          (click)="closeDevice(device.id, i)"
          class="actions"
        >
          <air-dynamic-icon
            *ngIf="device.status !== 0"
            name="trash"
            color="danger"
            [airTooltip]="'block device'"
          ></air-dynamic-icon>
          <!-- <air-dynamic-icon *ngIf="device.status" name="add" color="primary"  [airTooltip]="'open device'"></air-dynamic-icon> -->
        </button>
      </div>

      <div
        airLayout="row"
        ltSm.airLayout="column"
        airLayoutAlign="center"
        airColGap="s"
        airRowGap="s"
        airMarginTop="l"
        ltSm.airMarginTop="m"
        airMarginBottom="s"
      >
        <button
          air-button
          *ngIf="tooManyOpenedDevices"
          color="primary"
          airGridItem="6"
          ltSm.airGridItem="12"
          (click)="logout()"
          [disabled]="isLoading"
        >
          {{ 'header.user_menu.logout' | translate }}
        </button>

        <button
          air-button
          *ngIf="!tooManyOpenedDevices"
          color="primary"
          airGridItem="6"
          ltSm.airGridItem="12"
          (click)="close()"
          [disabled]="isLoading || tooManyOpenedDevices"
        >
          {{ 'devices.continue' | translate }}
        </button>
      </div>
    </div>
  </div>
</air-modal>
