import { Inject, Injectable } from '@angular/core';
import { OnAppInitAction } from '@shared/modules/air/air-modules/actions';
import { DOCUMENT } from '@angular/common';
import { WINDOW } from '@ng-web-apis/common';

@Injectable()
export class SetViewportHeightAction implements OnAppInitAction {
  constructor(
    @Inject(DOCUMENT) readonly document: Document,
    @Inject(WINDOW) readonly window: Window,
  ) {}

  onInit(): void {
    let vh = this.window.innerHeight * 0.01;
    this.document.documentElement.style.setProperty('--vh', `${vh}px`);
    this.window.addEventListener('resize', () => {
      vh = this.window.innerHeight * 0.01;
      this.document.documentElement.style.setProperty('--vh', `${vh}px`);
    });
  }
}
