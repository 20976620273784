<ng-container *ngIf="pagesService.onSitePage">
  <ng-container *ngIf="bSettings?.show_father_link">
    <div class="fixed-link" [ngClass]="direction">
      <div class="fixed-link--toggle">
        <div class="fixed-link--image"></div>
        <a airH4 class="fixed-link--header desktop-only" href="https://yedalms.io/" target="_blank">
          {{ 'father_contacts.link.text' | translate }}
        </a>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="bSettings?.show_father_phone && hSettings?.header_phone_number && authState.loggedIn">
    <a
      airLayout="row"
      airLayoutAlign="center center"
      class="father-contacts-phone"
      *airBreakpoints="[BreakpointName.ltMd]"
      [href]="'tel:' + hSettings?.header_phone_number | airReplaceString: ' '">
      <air-icon name="phone-full" size="24px 24px"></air-icon>
    </a>
  </ng-container>
</ng-container>
