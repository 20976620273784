import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';

import { AuthState } from '@state/auth/auth.state';
import { pages } from 'libraries/college-pages';
import { AuthSettings, AuthSettingsRepository, CollegeSettings } from 'libraries/college-settings';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthOptionalGuard implements CanActivate {
  constructor(
    private authState: AuthState,
    private router: Router,
    @Inject(AuthSettingsRepository) readonly authSettings: CollegeSettings<AuthSettings>,
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> {
    return this.authSettings.fetch().pipe(
      map((settings) => {
        if (!settings.signup_login_hide_homepage_if_not_auth) {
          return true;
        }

        const loggedIn = this.authState.loggedIn;
        if (loggedIn) {
          return true;
        } else {
          this.router.navigate([pages.auth.login]).then();
          return false;
        }
      }),
    );
  }
}
