import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '@env/environment';
import { api_routes } from '@app/services/api_routes/api_routes';
import { AuthState } from '@state/auth/auth.state';
import { CollegeEnvironmentRepository } from '@core/state/college-environment.state';

@Injectable({
  providedIn: 'root',
})
export class CallService {
  private readonly api_url: string;
  private readonly send_call_request_url: string;

  private get headers(): HttpHeaders {
    return new HttpHeaders({
      Accept: 'application/json',
      Authorization: 'Bearer ' + this.authService.loggedIn,
    });
  }

  constructor(
    private authService: AuthState,
    private httpClient: HttpClient,
    private collegeEnvironmentState: CollegeEnvironmentRepository,
  ) {
    this.api_url = environment.api_url;
    this.send_call_request_url = api_routes.send_call_request_url;
  }

  // @ts-ignore
  send(name, phone, email): Observable<any> {
    // send data to server
    return this.httpClient.post<number>(
      this.api_url + this.send_call_request_url,
      {
        name,
        phone,
        email,
        college_id: this.collegeEnvironmentState.collegeId,
      },
      { headers: this.headers },
    );
  }
}
