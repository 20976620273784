import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { AuthState } from '@state/auth/auth.state';
import { pages } from 'libraries/college-pages';

@Injectable({
  providedIn: 'root',
})
export class GuestGuard implements CanActivate {
  constructor(
    private authState: AuthState,
    private router: Router,
  ) {
  }

  canActivate(route: ActivatedRouteSnapshot) {
    if (this.authState.loggedIn) {
      if (route.url[0].path === 'auth') {
        let beforeAuthUrl = localStorage.getItem('beforeAuthUrl');
        if (beforeAuthUrl && beforeAuthUrl.includes('coupon')) {
          beforeAuthUrl = beforeAuthUrl.replace('coupon', 'about');
          return this.router.navigate([beforeAuthUrl]).then();
        }
        else {
          return this.router.navigate([pages.myStudies.courses]).then();
        }
      }
      else {
        return this.router.navigate([pages.myStudies.courses]).then();
      }
    }

    return true;
  }
}
