import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DirectionService } from '@airy-ui/direction';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ModalSupportComponent } from '@shared/modals/modal-support/modal-support.component';
import { TranslateModule } from '@ngx-translate/core';
import { CourseProps, CourseState } from '@pages/course/shared/state/course.state';
import { CollegeSettings, WebHookSettings, WebHookSettingsToken } from 'libraries/college-settings';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@Component({
  selector: 'yeda-widget-support',
  standalone: true,
  imports: [CommonModule, TranslateModule],
  templateUrl: './widget-support.component.html',
  styleUrl: './widget-support.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
@UntilDestroy()
export class WidgetSupportComponent implements OnInit {
  direction = this.directionService.direction;

  private modalConfig: MatDialogConfig = {
    hasBackdrop: true,
    panelClass: '',
    backdropClass: 'modal-overlay',
    maxWidth: '80vw'
  };

  constructor(
    private directionService: DirectionService,
    private dialog: MatDialog,
    readonly courseState: CourseState,
    @Inject(WebHookSettingsToken) readonly wbHookSettings: CollegeSettings<WebHookSettings>
  ) {
  }

  ngOnInit(): void {
    this.getData();
  }


  getData(): void {
    this.wbHookSettings.fetch().pipe(untilDestroyed(this)).subscribe();
  }

  openDialog(): void {
    const options: MatDialogConfig = { ...this.modalConfig };
    const dialogRef = this.dialog.open(ModalSupportComponent, { ...options });
  }

  showWidget(courseState: CourseProps, webHookSettings: WebHookSettings): boolean {
    return courseState.isCoursePage &&
      courseState.enableSupportTab &&
      courseState.hasAccess &&
      webHookSettings.enable_request_page &&
      webHookSettings.type_help_display === 'widget';
  }
}
