import { OnRouteEndAction } from '@shared/modules/air/air-modules/actions';
import { NavigationEnd } from '@angular/router';
import { inject, Injectable } from '@angular/core';
import { AuthState } from '@state/auth/auth.state';
import { ModalType } from '../../../../../../libraries/college-settings/src/lib/enum/modal-type.enum';
import { MatDialogRef } from '@angular/material/dialog';
import { TermsModalService } from '@shared/modals/terms-modal/shared/service/terms-modal.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TermsSettings } from 'libraries/college-settings';
import { firstValueFrom } from 'rxjs';

@Injectable()
@UntilDestroy()
export class ShowTermsAction implements OnRouteEndAction {
  readonly termsSettings = inject(TermsSettings);
  constructor(
    private authState: AuthState,
    private modalService: TermsModalService,
  ) {}

  onRouteEnd(event: NavigationEnd): void {
    this.showTerms().then();
  }

  private async showTerms(): Promise<void> {
    const termsSettings = await firstValueFrom(this.termsSettings.fetch());

    if (this.authState.loggedIn && termsSettings.show_college_terms) {
      if (this.authState.user.show_terms) {
        const modalRef = this.modalService.showModal(ModalType.TERMS) as MatDialogRef<any>;

        modalRef.afterClosed().pipe(untilDestroyed(this)).subscribe();
      }
    }
  }
}
